import { pdpSelectors } from '@ynap/product-details-state';
import { getProduct } from './getProduct';
import { getProductSEO } from './getProductSEO';
import { getProductSizeChart } from './getProductSizeChart';
import { productSelectors } from '@ynap/product-state';
import { createSelector } from 'reselect';
import queryString from 'query-string';
import { raygun } from '@ynap/clientside-monitoring';

const {
    getSelectedProductImages,
    getSelectedProductVideos,
    getSelectedProductPrice,
    getProductColours,
    getSelectedProductColour,
    getSelectedSize,
    getSelectedProductBadges,
    getColourSwatches,
} = pdpSelectors;

const { getBuyableStatus, getGenderFromAttributes } = productSelectors;

function flatten(item) {
    if (!item) return [];
    const { child, ...itemWithoutChild } = item;
    return [itemWithoutChild].concat(flatten(child));
}

function priceWithinLimits(thresholdItem, price, userCountry) {
    const [thresholdMin = 0, thresholdMax = Infinity] = thresholdItem[price.currency?.label] || thresholdItem[userCountry] || [];

    return price.finalPrice >= Number(thresholdMin) && price.finalPrice <= Number(thresholdMax);
}

function priceOverLimits(thresholdItem, price, userCountry) {
    const thresholdMax = thresholdItem[price.currency?.label] || thresholdItem[userCountry] || [];

    return price.finalPrice > Number(thresholdMax);
}

export const getSelectedProduct = createSelector(
    [
        (state) => state.pdp?.detailsState,
        (state) => state.pdp?.appState,
        (state) => state.pdp?.currencyConversion,
        (state) => state.userState,
        (state) => state.config,
        (state) => state.appState.locale,
        (state, props) => props?.messages,
        (state) => state?.router?.location?.search,
        (state) => state.appState.country,
        (state) => state.appState.defaultSalesCatalog,
    ],
    getSelectedProductInternal,
);

export const getConsideredBadge = (productAttributes, sustainabilityAttributes) =>
    productAttributes?.find((attribute) =>
        sustainabilityAttributes?.map((attr) => attr?.toLowerCase())?.includes(attribute?.identifier?.toLowerCase()),
);

//TODO: make this function independent of pdpState
export function getSelectedProductInternal(
    detailsState,
    appState,
    currencyConversion,
    userState,
    config,
    locale,
    messages,
    query,
    userCountry,
    defaultSalesCatalog,
) {
    const queryParams = queryString.parse(query);

    const { selectedPartNumber, selectedSku } = appState;
    const { user } = userState;

    const product = getProduct(detailsState);

    if (!product) {
        raygun.helpers.agent('send', {
            error: new Error(`getSelectedProductInternal error`),
            tags: [`error-getSelectedProductInternal`, `product is not defined`],
            customData: detailsState?.response?.body,
        });

        return null;
    }
    const {
        centralSizeScheme = '',
        shippingRestricted,
        partNumber,
        recommendations: recommendationsToFetch,
        name,
        designerName,
        designerNameEN,
        designerSeo,
        designerIdentifier,
        salesCategories,
        tracking,
    } = product;

    const modelPartNumber = partNumber;
    const productColours = getProductColours(product, selectedPartNumber);
    const selectedProductColour = getSelectedProductColour(productColours);
    const selectedProductName = selectedProductColour?.shortDescription ? selectedProductColour.shortDescription : name;
    const productAttributes = product?.attributes || [];
    const sustainabilityAttributes = Array.isArray(config?.head?.sustainabilityAttributes) ? config?.head?.sustainabilityAttributes : [config?.head?.sustainabilityAttributes];
    const consideredBadgebyProductColours = productColours?.find(({attributes: productAttributes = []} = {}) => getConsideredBadge(productAttributes, sustainabilityAttributes));
    const consideredBadge = getConsideredBadge(productAttributes, sustainabilityAttributes);
    const consideredLabels = consideredBadge?.values?.map((value) => value.label);

    const getSustainabilityDisclaimers = (product) => {
        const sustainabilityValues = product?.WCS_GRP_CONSIDERED_DETAILS?.values?.map((value) => value?.values?.map((value) => value?.label));
        return sustainabilityValues;
    };

    if (!selectedProductColour) {
        raygun.helpers.agent('send', {
            error: new Error(`getSelectedProductInternal error`),
            tags: [`error-getSelectedProductInternal`, `partNumber: ${partNumber}`, `selectedProductColour is not defined`],
        });
        return null;
    }

    const addToWishlistMode = queryParams?.mode === 'addToWishlist';
    //TODO: to remove queryParams.partNumber once NAPs on WCS
    const selectedSkuParam = { partNumber: queryParams?.['amp;partNumber'] };
    const selectedProductSize = getSelectedSize(selectedProductColour.sKUs, selectedSku || selectedSkuParam);
    const allLevelsProductAttributes = product?.attributes?.concat(selectedProductColour?.attributes, selectedProductSize?.attributes);

    const selectedProduct = { allLevelsProductAttributes, shippingRestricted, ...selectedProductColour, ...selectedProductSize, salesCategories };
    const { sizeConversion, sizeConversionBySku, sizeMeasurement, measurementType } = getProductSizeChart(selectedProduct.sKUs);
    const { application: { saleBadges } = {} } = config.global;
    const { availableImageSizes, brandName, imageQualityPercentage, luxWatchesImageQualityPercentage, zoomedImageQualityPercentage } = config.head;
    const pdpUrlTemplate = config.global.urlConfig.pdp.construct;
    const seo = getProductSEO(product, selectedProductColour, selectedProductSize, pdpUrlTemplate, locale, brandName);
    const masterCategory = flatten(detailsState?.response?.body?.products?.[0]?.masterCategory);

    const imageQuality = masterCategory[0]?.labelEN === 'Luxury Watches' ? luxWatchesImageQualityPercentage || imageQualityPercentage : imageQualityPercentage;
    const images = getSelectedProductImages(
        product,
        selectedProductColour,
        availableImageSizes,
        imageQuality,
        zoomedImageQualityPercentage,
        config.head.aspectRatio?.imagesphere,
    );

    const badgeBlocklist = config?.head?.pdp?.badgeBlocklist;

    /* Get front image (id == fr for MrP and view == F for TON) if not fallback to first image */
    const frontImage = images.find((img) => img.id === 'fr' || img.view === 'F') || images[0];
    const videosFirst = masterCategory.find((cat) => config?.head?.pdp?.videosFirstProductCategories?.indexOf(cat.identifier) > -1);
    const isOneSize = selectedProductColour.sKUs.length === 1 || selectedProductColour.sKUs[0]?.size?.centralSizeLabel === 'OneSize';

    const mmmValidCategory = config?.head?.matchMyMakeup?.category?.toLowerCase();
    const isMakeUpFoundation = !!masterCategory?.filter((cat) => cat.identifier?.toLowerCase().includes(mmmValidCategory))?.length;
    const hasMeaningfulSize = !['unsized', 'n/a', 'one size', 'onesize', 'yoox con'].includes(centralSizeScheme.toLowerCase());
    const sizeScheme = centralSizeScheme;

    const price = getSelectedProductPrice(selectedProduct, currencyConversion, userCountry);

    const buyable = getBuyableStatus({
        selectedProduct,
        price,
        user,
        locale,
        messages,
        productUrl: '',
        pdpMessageConfig: config?.head?.pdpMessageConfig,
        userCountry,
        defaultSalesCatalog,
        beautyExclude: config?.head?.beautyExclude,
        priceThresholds: config?.head?.pdp?.highPPMessageThresholds,
    });

    const {
        klarnaPromoMessage = [],
        klarnaPromoThreshold = {},
        fjwThreshold = {},
        klarnaCountryThreshold = {},
        klarnaExemptBrands = [],
    } = config?.head?.pdp || {};

    let fjwThresholdHandler = priceOverLimits(fjwThreshold, price, userCountry);

    let showKlarnaMessage = buyable.canAddToBag && klarnaPromoMessage.includes(userCountry) && !klarnaExemptBrands.includes(designerIdentifier?.toLowerCase());

    if (showKlarnaMessage) {
        showKlarnaMessage = priceWithinLimits(klarnaPromoThreshold, price, userCountry) && priceWithinLimits(klarnaCountryThreshold, price, userCountry);
    }

    return {
        isOneSize,
        name: selectedProductName,
        designerName: designerNameEN ? designerNameEN : designerName,
        designerIdentifier,
        // TODO: remove designerSeo, and move logic into getRelatedCategories selector
        designerSeo,
        ...selectedProduct,
        masterCategory,
        seo, //this will overrides raw seo data
        images,
        frontImage,
        videos: getSelectedProductVideos(selectedProductColour, images),
        videosFirst,
        price,
        badges: getSelectedProductBadges(selectedProductColour, selectedProductSize, saleBadges, badgeBlocklist, user),
        productNotes: getSelectedProductNotes(product, selectedProduct, pdpUrlTemplate, locale, config?.head?.pdp),
        colourSwatches: getColourSwatches(productColours, pdpUrlTemplate, locale, user, messages),
        // TODO: Sanitize sku data to only necessary stuff
        skus: selectedProductColour.sKUs,
        sizeLabel: selectedProductSize?.size?.labelSize,
        selectedSku: selectedProductSize, //if only one sku (one size) automatically select
        selectedSize: selectedProductSize?.size?.schemas?.find((schema) => schema.selected),
        sizeConversion: sizeConversion,
        sizeConversionBySku,
        sizeMeasurement,
        measurementType,
        forceLogIn: selectedProductColour?.forceLogIn, // forceLogIn only returned at variant level
        buyable,
        addToWishlistOnLoad: addToWishlistMode,
        colourPartNumber: selectedProductColour.partNumber,
        analyticsAttributes: getAnalyticsData(product, selectedProductColour),
        // TODO: ton ain't returning the recommendations information for the product, to be removed once the API's are aligned
        recommendationsToFetch: config?.global?.application?.brandId === 'theoutnet' ? ['YMAL_ProductRec'] : recommendationsToFetch,
        tracking,
        isMakeUpFoundation,
        hasMeaningfulSize,
        consideredBadge: consideredBadge?.label || consideredBadgebyProductColours?.label || null,
        consideredLabels: consideredLabels ? consideredLabels : null,
        sustainabilityDisclaimers: getSustainabilityDisclaimers(product),
        sizeScheme,
        showKlarnaMessage,
        fjwThresholdHandler,
        modelPartNumber,
    };
}

export const processEditorial = (editorialString, pdpUrlTemplate, locale) => {
    if (!editorialString) {
        return;
    }
    // Matches the format "[Awesome Product id123456]".
    return editorialString.replace(/\[([^\]]+)\sid(\d+)\]/g, function (match, name, pid) {
        return `<a id="internal" href="${productSelectors.getProductLinkByPartNumber(pdpUrlTemplate, locale, pid, pid)}">${name}</a>`;
    });
};

export const filterFitAndMeasurements = (product) => {
    // Holds product size, fit and measurement details
    const fitAndMeasurements = [...(product?.WCS_GRP_FIT_DETAILS?.values || []), ...(product?.WCS_GRP_MEASUREMENTS?.values || [])];

    // Filterd out all keys with 'label' we don't require the description to be visible on the PDP
    return fitAndMeasurements?.map((value) => {
        return Object.keys(value).reduce((object, key) => (key !== 'label' ? { ...object, [key]: value[key] } : object), {});
    });
};

export const getSelectedProductNotes = (product, selectedProduct, pdpUrlTemplate, locale, config) => {
    const showSkuMeasurements = !!config?.showSkuMeasurements;

    // MRP / NAP - only show Sku product measurements in product notes for One Size products i.e. bags, accessories
    // TON show Sku product measurements in product notes on size select i.e. High heel shoes
    const measurements = (selectedProduct.sKUs?.length === 1 || showSkuMeasurements) && selectedProduct?.WCS_GRP_MEASUREMENTS?.values;

    return {
        technicalDescription: selectedProduct.technicalDescription,
        editorialDescription: processEditorial(selectedProduct.editorialDescription, pdpUrlTemplate, locale),
        sizeAndFit: product.sizeAndFit,
        detailsAndCare: selectedProduct.detailsAndCare,
        fitAndMeasurements: [...(filterFitAndMeasurements(product) || []), ...(measurements || [])],
    };
};

export const getSelectedProductVariantColourPartNumbers = (product) => {
    return getProductColours(product).map((colour) => colour.partNumber);
};

export const getAnalyticsData = (product, selectedProductColour) => ({
    department: product?.attributes?.find(({ identifier }) => identifier === 'Department')?.values[0]?.label,
    season: selectedProductColour?.attributes?.find(({ identifier }) => identifier === 'Sale Season')?.values[0]?.label,
    badges: product?.badges,
    sustainabilityFlag: !!product.attributes?.find(({ identifier }) => identifier === 'CONSIDERED'),
    gender: getGenderFromAttributes(product.attributes),
});
