import queryStringUtils from 'query-string';
import { DEFAULT_PRICE_MIN } from './PriceFilterGroup';

export const constrainToMinPrice = (val) => Math.max(DEFAULT_PRICE_MIN, val);

export const constrainToMaxPrice = (defaultPriceMax, val) => Math.min(defaultPriceMax, val);

export const roundPriceToLowerStep = (input, step, defaultPriceMax) => {
    if (input === defaultPriceMax) {
        return defaultPriceMax - step;
    }
    // const rounded = Math.floor(input / step) * step;
    return constrainToMinPrice(input);
};

export const roundPriceToUpperStep = (input, step, defaultPriceMax) => {
    if (input === DEFAULT_PRICE_MIN) {
        return step;
    }
    // const rounded = Math.floor(input / step) * step;
    // const remainder = input % step === 0 ? 0 : step;
    return Math.min(defaultPriceMax, input);
};

export const calculatePriceValue = (price) => {
    const { amount, divisor } = price;
    if (isNaN(amount) || isNaN(divisor)) {
        throw new Error(`Expected valid price object but got { amount: ${amount}, divisor: ${divisor} }`);
    }
    const priceTypeNumber = parseInt(amount, 10) / parseInt(divisor, 10);
    return priceTypeNumber;
};

export const addPriceToQueryString = (priceRange, queryString) => {
    const [min, max] = priceRange;
    /* eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars */
    const queryParams = queryStringUtils.parse(queryString);
    queryParams.minPrice = min;
    queryParams.maxPrice = max;
    if (queryParams.pageNumber) queryParams.pageNumber = 1;

    return `?${queryStringUtils.stringify(queryParams)}`;
};

export const getDomain = (filter) => {
    return [0, filter.defaultPriceMax];
};

export const getCurrentRange = (filter, location) => {
    const { minPrice, maxPrice } = queryStringUtils.parse(location.search);
    const { defaultPriceMax, plpMinPrice, step } = filter;
    const rangeLower = minPrice && Number(minPrice) ? Number(minPrice) : (plpMinPrice ?? 0);
    const rangeHigher = maxPrice && Number(maxPrice) ? Number(maxPrice) : defaultPriceMax;
    return [roundPriceToLowerStep(rangeLower, step, defaultPriceMax), roundPriceToUpperStep(rangeHigher, step, defaultPriceMax)];
};

export const getCurrentRealRange = (location) => {
    const { minPrice, maxPrice } = queryStringUtils.parse(location.search);
    const rangeLower = minPrice && Number(minPrice) ? Number(minPrice) : 0;
    const rangeHigher = maxPrice && Number(maxPrice) ? Number(maxPrice) : '';
    return [rangeLower, rangeHigher];
};

export const getCurrency = (filter) => {
    return filter?.currency?.symbol;
};

export const addPriceQueryParamsToPath = ({ history: { location, push }, range }) => {
    const { pathname, search } = location;
    const queryStringWithPrice = addPriceToQueryString(range, search);

    return push(`${pathname}${queryStringWithPrice}`, { pidOnlySkeleton: true });
};
