import React, { PureComponent, Suspense } from 'react';
import { PropTypes } from 'prop-types';
import { FiltersWrapper, FilterTags } from '../../index';
import { OrderBy } from '@ynap/product-listing-page';
import { ContentHeader } from '@ynap/header';
import PaginationWithAnalytics from '@ynap/pagination';
import { ScrollToTop } from '@ynap/scroll-to-top';
import BEMHelper from '@ynap/bem-helper';
import Skeleton from '@ynap/skeleton';
import { ValueTransition } from '@ynap/value-transition';
import track, { TrackingPropType } from 'react-tracking';
import { withAnalytics, safeBuildEventObj } from '@ynap/analytics';
import ExternalRecommendationsWrapper from '../../ExternalRecommendations/ExternalRecommendationsWrapper';

import {
    trackRefine,
    trackFilter,
    trackFavorites,
    trackFilterToggle,
    trackPagination,
    trackLoadMore,
    trackBackToTop,
    trackSaleToggle,
    trackDesignersSearchFocus,
    trackDesignersSearchEntry,
} from '../../analytics';
import { trackProductReplacementClick } from '../../../product-replacement';
import ProductListLayout from '../../ProductList/ProductListWithLoadMore';
import { ProductSummaryWithLink as ProductSummary } from '@ynap/product-summary';
import { Features } from '@ynap/feature-selector';
import PLPEmptyPage from '../../PLPEmptyPage/PLPEmptyPage';
import CoremediaContent, { hasSaleContent } from './CoremediaContent';

const bem = new BEMHelper('ProductListingPage');
const PLPOverlay = React.lazy(() => import(/* webpackChunkName: "PLPOverlay" */ './PLPOverlay'));

@track()
export class ProductListingPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modal: {
                key: null,
            },
        };

        // Binding here instead of arrow for testing with decorator.
        this.setFilterModalVisibility = this.setFilterModalVisibility.bind(this);
        this.trackFilterEvent = this.trackFilterEvent.bind(this);
        this.trackFavoritesEvent = this.trackFavoritesEvent.bind(this);
        this.trackFilterToggleClick = this.trackFilterToggleClick.bind(this);
        this.trackPaginationEvent = this.trackPaginationEvent.bind(this);
        this.trackLoadMoreEvent = this.trackLoadMoreEvent.bind(this);
        this.trackBackToTopEvent = this.trackBackToTopEvent.bind(this);
        this.trackSaleToggleEvent = this.trackSaleToggleEvent.bind(this);
        this.trackProductReplacementClickEvent = this.trackProductReplacementClickEvent.bind(this);
        this.trackDesignersSearchFocusEvent = this.trackDesignersSearchFocusEvent.bind(this);
        this.trackDesignersSearchEntryEvent = this.trackDesignersSearchEntryEvent.bind(this);
        this.setModal = this.setModal.bind(this);
    }

    static defaultProps = {
        ssr: false,
    };

    setModal(key = null) {
        this.setState({
            modal: {
                key,
            },
        });
    }

    @track(({ analytics }, state, [, modifier]) => safeBuildEventObj(trackRefine, analytics, modifier))
    setFilterModalVisibility(visible) {
        this.setState({
            modalVisible: visible,
        });
    }

    @track(({ analytics, useIdentifier }, state, [filter, type, subType, position]) =>
        safeBuildEventObj(trackFilter, analytics, filter, type, subType, useIdentifier, position),
    )
    trackFilterEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }) => safeBuildEventObj(trackFavorites, analytics))
    trackFavoritesEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [filter, type, toggleStatus]) => safeBuildEventObj(trackFilterToggle, analytics, filter, type, toggleStatus))
    trackFilterToggleClick() {}

    /* istanbul ignore next */
    @track((props, state, [copy, url]) => trackSaleToggle(copy, url))
    trackSaleToggleEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [value]) => safeBuildEventObj(trackPagination, analytics, value))
    trackPaginationEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [value, currentPage]) => safeBuildEventObj(trackLoadMore, analytics, value, currentPage))
    trackLoadMoreEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [currentPage]) => safeBuildEventObj(trackBackToTop, analytics, currentPage))
    trackBackToTopEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [value]) => safeBuildEventObj(trackProductReplacementClick, analytics, value))
    trackProductReplacementClickEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [value, currentPage]) => safeBuildEventObj(trackDesignersSearchFocus, analytics, value, currentPage))
    trackDesignersSearchFocusEvent() {}

    /* istanbul ignore next */
    @track(({ analytics }, state, [value, currentPage]) => safeBuildEventObj(trackDesignersSearchEntry, analytics, value, currentPage))
    trackDesignersSearchEntryEvent() {}

    /* istanbul ignore next */
    generateListingUrlFunction = () => {
        return this.props.generateListingUrlWithState({ pidOnlySkeleton: true });
    };

    generateImageSrcsetSize() {
        // moved to config as also used by meta data to preload responsive images
        return this.props.imageSrcsetSizes || '(min-width: 768px) 25vw, 50vw';
    }

    renderOverlay() {
        return (
            <Suspense fallback={''}>
                <PLPOverlay content={this.state.modal.key} setModal={this.setModal} {...this.props} />
            </Suspense>
        );
    }

    renderProducts(enableLoadMore, hasAppliedFilters) {
        let {
            productLists = [],
            messages,
            loading: { loadingRequest: loading },
            productImageAspectRatio,
            disableSPA,
            paginationData,
            locale,
            metaData,
            baseUrl,
            footerAd,
            mainContent,
            fetchMoreListing,
            firstFetchedPageNumber,
            lastFetchedPageNumber,
            setListingHeight,
            currentPage,
            forceLogin,
            ssr,
            productReplacementConfig,
            urlConfig,
            internal_urls,
            customPageSize,
            imageQualityPercentage,
            preloadedProductItems,
            pageType,
            emptyListGoToUrl,
            headerData,
            isPageVisible,
            country,
        } = this.props;

        const imageSrcsetSize = this.generateImageSrcsetSize();
        return (productLists.length === 0 || productLists[0]?.products?.length === 0) && !loading ? (
            // Call for empty list copy here without pageType as a result of filtering.
            <PLPEmptyPage
                messages={messages}
                pageType={pageType}
                generic={pageType !== 'DESIGNER'}
                emptyListGoToUrl={emptyListGoToUrl}
                locale={locale}
                headerData={headerData}
                isPageVisible={isPageVisible}
                hasAppliedFilters={hasAppliedFilters}
            />
        ) : (
            <Features>
                <ProductListLayout
                    forceLogin={forceLogin}
                    fetchMoreListing={fetchMoreListing}
                    ProductSummary={ProductSummary}
                    productLists={productLists}
                    className={bem('productItem')}
                    productImageAspectRatio={productImageAspectRatio}
                    messages={messages}
                    loading={loading}
                    imageSrcsetSize={imageSrcsetSize}
                    disableSPA={disableSPA}
                    metaData={metaData}
                    locale={locale}
                    baseUrl={baseUrl}
                    generateListingUrl={this.generateListingUrlFunction()}
                    paginationData={paginationData}
                    totalPages={paginationData && paginationData.totalPages}
                    enableLoadMore={enableLoadMore}
                    firstFetchedPageNumber={firstFetchedPageNumber}
                    lastFetchedPageNumber={lastFetchedPageNumber}
                    footerAd={footerAd}
                    renderCoreMediaContent={this.renderCoreMediaContent}
                    productReplacementConfig={productReplacementConfig}
                    trackProductReplacementClickEvent={this.trackProductReplacementClickEvent}
                    mainContent={mainContent}
                    trackLoadMoreEvent={this.trackLoadMoreEvent}
                    setListingHeight={setListingHeight}
                    currentPage={currentPage}
                    trackBackToTopEvent={() => this.trackBackToTopEvent(currentPage)}
                    ssr={ssr}
                    urlConfig={urlConfig}
                    internal_urls={internal_urls}
                    customPageSize={customPageSize}
                    imageQualityPercentage={imageQualityPercentage}
                    preloadedProductItems={preloadedProductItems}
                    country={country}
                />
            </Features>
        );
    }

    renderCoreMediaContent(data, rules) {
        return <CoremediaContent data={data} rules={rules} />;
    }

    render() {
        const {
            sortOptions,
            paginationData,
            messages,
            filters,
            location,
            urlConfig,
            internal_urls,
            locale,
            className,
            loading,
            headerData,
            carouselContent,
            productLists,
            designerSaleToggleData,
            isPageVisible,
            pageType,
            forceLogin,
            emptyListContent,
            noProductsData,
            footerData,
            externalRecommendations,
            enableLoadMore,
            isFetchingOptimizelyAlgorithmicSort,
            currentPage,
            updateSelectedSchema,
            getFilterGroup,
            filterCategoryByPath,
            facetConfig,
            ssr,
            customPageSize,
            emptyListGoToUrl,
            useIdentifier,
            headerActions,
            zoomedImageQualityPercentage,
            imageQualityPercentage,
            country,
            config: { head: { minimumPriceCountries, omnibusMessageConfig, seo, plp: { preFooterContent = false } = {} } = {} } = {},
            saleExclusive,
            user,
        } = this.props;

        const { loadingRequest, pidOnly } = loading;
        const fullReload = loadingRequest && !pidOnly;
        const { modalVisible } = this.state;
        const hasAppliedFilters = filters?.filter?.((item) => item?.appliedLabels?.length)?.length;
        const modifier = hasAppliedFilters ? 'filterApplied' : '';
        const emptyList = filters.length === 0 && productLists.length === 1 && productLists[0].products.length === 0;
        const emptySearch = emptyList && pageType === 'SEARCH';
        const rawTags = headerData.tags || [];
        const tags = pageType === 'EIP_PREVIEW' ? [...rawTags, 'eip-title'] : rawTags;
        const scrollModifer = enableLoadMore ? 'scroll' : null;
        const emptyListModifier = emptyList ? 'emptyList' : null;
        const results = paginationData.results || paginationData.recordSetTotal;
        const isCountOver16 = paginationData.recordSetCount > 16;
        const hasCarouselContent = carouselContent ? true : false;
        const saleContent = hasSaleContent(headerData?.contentTop);
        const showContent = pageType !== 'SEARCH' && !isFetchingOptimizelyAlgorithmicSort;
        const showExternalRecommendations = showContent && externalRecommendations;
        const isMenswearOnTON = location.pathname.includes('/mens/');
        const isEUcountry = minimumPriceCountries?.includes(country?.toUpperCase?.());

        return (
            <div className={bem(null, [scrollModifer, emptyListModifier], className)}>
                {!emptySearch &&
                    isPageVisible &&
                    (saleContent.length > 0 ? (
                        <div className={bem('gutterWrapper')}>
                            <div className={bem('saleBannerTop')}>{this.renderCoreMediaContent(saleContent)}</div>
                        </div>
                    ) : (
                        <ContentHeader
                            loading={fullReload}
                            title={headerData.title}
                            subTitle={headerData.subTitle}
                            preTitle={headerData.preTitle}
                            saleTitle={headerData.saleTitle}
                            subCategoryLinks={headerData.subCategoryLinks}
                            copy={headerData.copy}
                            tags={tags}
                            banners={headerData.banners}
                            urlConfig={urlConfig}
                            internal_urls={internal_urls}
                            locale={locale}
                            messages={messages.contentHeader}
                            ssr={ssr}
                            hasCarouselContent={hasCarouselContent}
                            headerActions={headerActions}
                            picturesAndMedia={headerData.picturesAndMedia}
                            imageQuality={zoomedImageQualityPercentage}
                            user={user}
                        />
                    ))}
                {!emptySearch &&
                    hasCarouselContent &&
                    this.renderCoreMediaContent(carouselContent, (props) => ({
                        ...props,
                        locale,
                        urlConfig,
                        internal_urls,
                        imageQualityPercentage,
                    }))}

                <div id="scroll-point"></div>

                {/* Can't apply padding to ScrollToTopWrapper directly as content offset child element uses width 100% so gets skewed otherwise */}
                {/*TO DO Log analytics events using Raygun */}

                {!loadingRequest && (emptyList || (!isPageVisible && !forceLogin)) ? (
                    <>
                        <PLPEmptyPage
                            isPageVisible={isPageVisible}
                            messages={messages}
                            pageType={pageType}
                            headerData={headerData}
                            emptyListContent={emptyListContent}
                            emptyListGoToUrl={emptyListGoToUrl}
                            locale={locale}
                        />
                        {this.renderCoreMediaContent(noProductsData, (props) => ({
                            ...props,
                            locale,
                            urlConfig,
                            internal_urls,
                            imageQualityPercentage,
                        }))}
                    </>
                ) : (
                    // CM component
                    <section className={bem('gutterWrapper')}>
                        {
                            // todo remove plp-filter-refine param after nap migration
                        }

                        <div className={bem('refine', null, 'plp-filter-refine')}>
                            {/*
                                            <div className={bem('skeleton')}>
                                            <Skeleton count={2} align="left" className={bem('skeletonWidth')} />
                                        </div>
                                        RE: Refine button skeleton: eventually the spike into the custom orderBy component might let us move the refine button inside filterWrapper,
                                        where it can have it's own component skeleton state. */}

                            {fullReload ? (
                                <div className={bem('skeleton')}>
                                    <Skeleton />
                                </div>
                            ) : (
                                <div className={bem('filterResults')}>
                                    <span
                                        role="button"
                                        className={bem('filterToggle', modifier)}
                                        onClick={() => {
                                            this.setFilterModalVisibility(true, 'open');
                                        }}
                                    >
                                        {messages.productListing.refine()}
                                    </span>
                                    <ValueTransition
                                        component="span"
                                        className={bem('totalProducts')}
                                        classAnimationModifier={bem('totalProducts', 'changing')}
                                        animationName="fadeOut"
                                    >
                                        {messages.generic.results({ copy: results })}
                                    </ValueTransition>
                                </div>
                            )}
                            <OrderBy
                                className={bem('layoutOrderBy')}
                                sortOptions={sortOptions}
                                generateListingUrl={this.generateListingUrlFunction()}
                                messages={messages}
                                loading={fullReload}
                            />
                        </div>
                        {this.state.modal.key === 'priceInformation' && this.renderOverlay()}
                        {saleExclusive && isEUcountry && omnibusMessageConfig && (
                            <div className={bem('omnibusDivContainer')}>
                                <div className={bem('omnibusMessage')}>{messages?.omnibusPLPMessage?.default()}</div>
                                <button className={bem('priceDetailsIcon')} onClick={() => this.setModal('priceInformation')} />
                            </div>
                        )}
                        <div className={bem('layoutGridWrapper')}>
                            <FiltersWrapper
                                useIdentifier={useIdentifier}
                                className={bem('filter')}
                                messages={messages}
                                modalVisible={modalVisible}
                                toggleFilter={this.setFilterModalVisibility}
                                accordionAnimation={this.props.accordionAnimation}
                                loading={loading}
                                filters={filters}
                                generateListingUrlFunction={this.generateListingUrlFunction}
                                designerSaleToggleData={designerSaleToggleData}
                                updateSelectedSchema={updateSelectedSchema}
                                getFilterGroup={getFilterGroup}
                                filterCategoryByPath={filterCategoryByPath}
                                trackFilterEvent={this.trackFilterEvent}
                                trackFavoritesEvent={this.trackFavoritesEvent}
                                trackFilterToggleClick={this.trackFilterToggleClick}
                                trackSaleToggleEvent={this.trackSaleToggleEvent}
                                facetConfig={facetConfig}
                                trackDesignersSearchFocus={this.trackDesignersSearchFocusEvent}
                                trackDesignersSearchEntry={this.trackDesignersSearchEntryEvent}
                                country={country}
                            />
                            <div className={bem('layoutGrid')}>
                                <FilterTags
                                    className={bem('filterTags')}
                                    filters={filters}
                                    location={location}
                                    generateListingUrl={this.generateListingUrlFunction()}
                                    loading={fullReload}
                                    trackFilterEvent={this.trackFilterEvent}
                                    filterCategoryByPath={filterCategoryByPath}
                                />
                                {this.renderProducts(enableLoadMore, hasAppliedFilters)}

                                {!enableLoadMore && isCountOver16 && isPageVisible && !!footerData && preFooterContent && (
                                    <ScrollToTop trackBackToTopEvent={() => this.trackBackToTopEvent(currentPage)} className={bem('scrollTop')} />
                                )}
                                {!emptyList && !enableLoadMore && isPageVisible && !!footerData && preFooterContent && (
                                    <PaginationWithAnalytics
                                        currentPage={paginationData && paginationData.pageNumber}
                                        totalPages={paginationData && paginationData.totalPages}
                                        location={location}
                                        messages={messages}
                                        generateListingUrl={this.generateListingUrlFunction()}
                                        customPageSize={customPageSize}
                                        className={bem('PaginationMarginExperiment')}
                                    />
                                )}
                                {this.renderCoreMediaContent(footerData, (props) => ({
                                    ...props,
                                    locale,
                                    urlConfig,
                                    internal_urls,
                                }))}
                            </div>
                        </div>
                    </section>
                )}
                {!enableLoadMore && isCountOver16 && isPageVisible && (!footerData || !preFooterContent) && (
                    <ScrollToTop trackBackToTopEvent={() => this.trackBackToTopEvent(currentPage)} className={bem('scrollTop')} />
                )}
                {!emptyList && !enableLoadMore && isPageVisible && (!footerData || !preFooterContent) && (
                    <PaginationWithAnalytics
                        currentPage={paginationData && paginationData.pageNumber}
                        totalPages={paginationData && paginationData.totalPages}
                        location={location}
                        messages={messages}
                        generateListingUrl={this.generateListingUrlFunction()}
                        customPageSize={customPageSize}
                    />
                )}
                {showExternalRecommendations && (
                    <ExternalRecommendationsWrapper
                        content={externalRecommendations}
                        urlConfig={urlConfig}
                        internal_urls={internal_urls}
                        locale={locale}
                        messages={messages}
                    />
                )}
            </div>
        );
    }
}

ProductListingPage.propTypes = {
    loading: PropTypes.shape({
        loadingRequest: PropTypes.bool.isRequired,
        pidOnly: PropTypes.bool,
    }).isRequired,
    productLists: PropTypes.array,
    urlTemplates: PropTypes.shape({
        partNumber: PropTypes.string.isRequired,
    }),
    sortOptions: PropTypes.array.isRequired,
    paginationData: PropTypes.shape({
        pageNumber: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        recordSetTotal: PropTypes.number.isRequired,
        recordSetCount: PropTypes.number.isRequired,
        results: PropTypes.string,
    }),
    messages: PropTypes.shape({
        accountUtility: PropTypes.shape({
            signupMessage: PropTypes.func.isRequired,
            emailLabel: PropTypes.func.isRequired,
        }),
        generic: PropTypes.shape({
            result: PropTypes.func.isRequired,
            results: PropTypes.func.isRequired,
        }).isRequired,
        productListing: PropTypes.shape({
            sortBy: PropTypes.func.isRequired,
            emptyList: PropTypes.func.isRequired,
            emptyListCopy: PropTypes.func.isRequired,
            emptyListGoTo: PropTypes.func.isRequired,
            refine: PropTypes.func.isRequired,
        }).isRequired,
        pagination: PropTypes.shape({
            previous: PropTypes.func.isRequired,
            status: PropTypes.func.isRequired,
            next: PropTypes.func.isRequired,
        }),
        contentHeader: PropTypes.func.isRequired,
    }),
    filters: PropTypes.array.isRequired,
    generateListingUrlWithState: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    className: PropTypes.string,
    getFilterGroup: PropTypes.func.isRequired,
    updateSelectedSchema: PropTypes.func.isRequired,
    accordionAnimation: PropTypes.bool,
    productImageAspectRatio: PropTypes.string,
    headerData: PropTypes.shape({
        tags: PropTypes.array,
        copy: PropTypes.string,
        banners: PropTypes.object,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        preTitle: PropTypes.string,
        saleTitle: PropTypes.bool,
        contentTop: PropTypes.object,
        subCategoryLinks: PropTypes.array,
    }),
    footerData: PropTypes.object,
    noProductsData: PropTypes.object,
    emptyListContent: PropTypes.object,
    carouselContent: PropTypes.object,
    externalRecommendations: PropTypes.object,
    mainContent: PropTypes.object,
    country: PropTypes.string,
    analytics: PropTypes.shape({
        category: PropTypes.shape({
            pageType: PropTypes.string.isRequired,
            subCategory1: PropTypes.string.isRequired,
        }),
    }),
    disableSPA: PropTypes.bool,
    pageType: PropTypes.string,
    selectedCategories: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            identifier: PropTypes.string,
            attributes: PropTypes.array,
            seo: PropTypes.object,
            seoURLKeyword: PropTypes.string,
        }),
    ),
    isPageVisible: PropTypes.bool,
    tracking: TrackingPropType,
    facetConfig: PropTypes.object,
    locale: PropTypes.string,
    metaData: PropTypes.object,
    baseUrl: PropTypes.string,
    footerAd: PropTypes.object,
    fetchMoreListing: PropTypes.func,
    firstFetchedPageNumber: PropTypes.number,
    lastFetchedPageNumber: PropTypes.number,
    filterCategoryByPath: PropTypes.string,
    urlConfig: PropTypes.object,
    internal_urls: PropTypes.array,
    forceLogin: PropTypes.bool,
    enableLoadMore: PropTypes.bool,
    setListingHeight: PropTypes.func,
    currentPage: PropTypes.number,
    designerSaleToggleData: PropTypes.shape({
        label: PropTypes.string,
        seo: PropTypes.shape({
            seoURLKeyword: PropTypes.string,
        }),
        pageType: PropTypes.string,
    }),
    ssr: PropTypes.bool.isRequired,
    productReplacementConfig: PropTypes.object,
    customPageSize: PropTypes.number,
    emptyListGoToUrl: PropTypes.string,
    useIdentifier: PropTypes.bool,
    headerActions: PropTypes.node,
};

export default withAnalytics(ProductListingPage);
