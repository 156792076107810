import queryString from 'query-string';

export const trackCoremediaModuleClick = ({ analytics, href, modulePosition }) => {
    return {
        event: 'site merchandising - click',
        effect: 'clicked on a coremedia module',
        category: {
            primaryCategory: 'site merchandising',
            subCategory: analytics.page?.category?.pageType,
        },
        attributes: {
            siteMerchandising: {
                ...getTrackingFromUrl(href),
                ...(modulePosition ? { modulePosition } : {}),
            },
        },
    };
};

export const trackCarouselInteraction = ({ analytics, index, arrow }) => ({
    event: `coremedia module - ${arrow}`,
    eventEffect: 'interacted with coremedia module',
    category: {
        primaryCategory: 'coremedia module',
        subCategory: analytics.page?.category?.pageType,
    },
    attributes: {
        position: `TopCarousel${index + 1}`,
    },
});

export const getTrackingFromUrl = (href = '') => {
    const { query = {} } = queryString.parseUrl(href);
    const { cm_sp } = query;
    if (cm_sp) {
        const attributes = cm_sp.split('-_-');

        if (attributes.length !== 4) return {};

        return {
            placement: attributes[0],
            position: attributes[1],
            campaignName: attributes[2],
            campaignStartDate: attributes[3],
        };
    }
    return {};
};
