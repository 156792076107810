import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import { withAnalytics, safeBuildEventObj } from '@ynap/analytics';
import { trackExternalRecommendationsModuleClick } from '../../analytics/externalRecommendations';
import { linkRenderer } from '@ynap/router';
import track from 'react-tracking';

const bem = new BEMHelper('ExternalRecommendationsList');

interface ExternalRecommendationsListProps {
    items?: {
        title: string
        href: string
    }[];
    urlConfig: { [key: string]: object };
    locale: string;
    internal_urls: string[];
    messages: {
        featuredCategories: {
            heading: () => string 
        }
    };
    analytics: unknown;
    tracking: {
        trackEvent: (props: unknown) => unknown
    }
}

const ExternalRecommendationsList: React.FC<ExternalRecommendationsListProps> = ({ items = [], locale, urlConfig, internal_urls, analytics, tracking, messages }) => {
    if (!items?.length) { return null }

    return (
        <section className={bem()}>
            <p className={bem('featuredCategories')}>{messages?.featuredCategories?.heading()}</p>
            <ul className={bem('itemsWrapper')}>
                {items.map((item, index) => (
                    <li
                        key={item.href}
                        className={bem('title')}
                        onClick={() => {
                            tracking.trackEvent(
                                safeBuildEventObj(trackExternalRecommendationsModuleClick, { analytics, item, totalLinks: items.length, linkPosition: index }),
                            );
                        }}
                    >
                        {linkRenderer(urlConfig, internal_urls, locale, item.href, item.title)}
                    </li>
                ))}
            </ul>
        </section>
    );
};

const ExternalRecommendationsListContainer = track()(withAnalytics(ExternalRecommendationsList));



export default ExternalRecommendationsListContainer;
