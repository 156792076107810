import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import ContentItem from './ContentItem/ContentItem';
import { withAnalytics, safeBuildEventObj } from '@ynap/analytics';
import { trackCarouselInteraction, trackCoremediaModuleClick } from '../analytics/coremedia';
import track from 'react-tracking';
import ViewportObserver from '@ynap/viewport-observer';

const bem = new BEMHelper('ContentCarousel');

const ContentCarousel = (props) => {
    const {
        item = {},
        locale,
        urlConfig = {},
        internal_urls = [],
        imageQualityPercentage,
        onClick = () => {},
        analytics,
        tracking,
        loading,
        slideModifiers,
    } = props;
    const wrapperRef = useRef(null);
    const [scrollAttr, setScrollAttr] = useState({
        rightEdge: true,
        leftEdge: true,
        scrollable: false,
    });

    const { rightEdge, leftEdge, scrollable } = scrollAttr;
    const { scrollWidth, clientWidth } = wrapperRef?.current || {};

    useEffect(() => {
        setScrollAttr({
            ...scrollAttr,
            scrollable: scrollWidth > clientWidth,
        });
    }, [scrollWidth, clientWidth]);

    const trackArrowClick = (arrow) => {
        const { scrollWidth, scrollLeft } = wrapperRef.current;
        const slideWidth = Math.round(scrollWidth / item.items?.length);
        const index = Math.floor(scrollLeft / slideWidth);
        tracking.trackEvent(
            safeBuildEventObj(trackCarouselInteraction, {
                analytics,
                index,
                arrow,
            }),
        );
    };

    const nextClick = () => {
        trackArrowClick('next');
        smoothScrollWithPolyfill(wrapperRef.current, document.body.style, true);
    };

    const prevClick = () => {
        trackArrowClick('previous');
        smoothScrollWithPolyfill(wrapperRef.current, document.body.style);
    };

    const onObserving = (index) => (isIntersecting) => {
        if (index === 0) {
            setScrollAttr({ ...scrollAttr, leftEdge: isIntersecting });
        }
        if (index === item.items?.length - 1) {
            setScrollAttr({ ...scrollAttr, rightEdge: isIntersecting });
        }
    };

    return (
        <section className={bem(null, null, 'sectionWrapper')}>
            {item.teaserTitle && <h3 className={bem('header')}>{item.teaserTitle}</h3>}
            <div className={bem('carousel')} aria-roledescription="carousel">
                {scrollable && (
                    <>
                        <button
                            type="button"
                            className={bem('control', 'prev', { ...slideModifiers })}
                            disabled={leftEdge}
                            aria-disabled={leftEdge}
                            onClick={prevClick}
                            aria-label="Previous slide"
                        />
                    </>
                )}
                <div className={bem('slidesWrapperContainer')} ref={wrapperRef}>
                    <div className={bem('slidesWrapper')}>
                        {item.items?.map((item, index) => (
                            <ViewportObserver
                                onObserving={onObserving(index)}
                                keepObserving={true}
                                className={bem('slide', { loading, ...slideModifiers })}
                                key={item.href}
                                threshold={0.9}
                            >
                                {() => (
                                    <ContentItem
                                        contentItem={item}
                                        locale={locale}
                                        urlConfig={urlConfig}
                                        internal_urls={internal_urls}
                                        imageQualityPercentage={imageQualityPercentage}
                                        onClick={() => {
                                            tracking.trackEvent(
                                                safeBuildEventObj(trackCoremediaModuleClick, {
                                                    analytics,
                                                    href: item.href,
                                                    modulePosition: `carousel${index + 1}`,
                                                }),
                                            );
                                            return onClick;
                                        }}
                                    />
                                )}
                            </ViewportObserver>
                        ))}
                    </div>
                </div>
                {scrollable && (
                    <>
                        <button
                            type="button"
                            className={bem('control', 'next', { ...slideModifiers })}
                            disabled={rightEdge}
                            aria-disabled={rightEdge}
                            onClick={nextClick}
                            aria-label="Next slide"
                        />
                    </>
                )}
            </div>
        </section>
    );
};

export const smoothScrollWithPolyfill = (scrollEl, styleDeclaration, moveRight) => {
    const scrollDistance = scrollEl.clientWidth;
    const scroll = moveRight ? scrollDistance : -scrollDistance;

    if ('scroll-behavior' in styleDeclaration) {
        scrollEl.scrollLeft += scroll;
    } else {
        let smoothInterval;
        const initialLeft = scrollEl.scrollLeft;
        const framesPerSecond = 30;
        const intervalMs = 500 / framesPerSecond;
        const increment = scroll / framesPerSecond;

        smoothInterval = setInterval(() => {
            scrollEl.scrollLeft += increment;
            const { clientWidth, scrollWidth, scrollLeft } = scrollEl;
            const leftStop = initialLeft + scroll >= scrollLeft || scrollLeft <= 0;
            const rightStop = initialLeft + scroll <= scrollLeft || scrollLeft == scrollWidth - clientWidth;
            const stop = moveRight ? rightStop : leftStop;
            if (stop) {
                clearInterval(smoothInterval);
            }
        }, intervalMs);
    }
};

const ContentCarouselContainer = track()(withAnalytics(ContentCarousel));
ContentCarouselContainer.layoutVariant = ['yos-plp-content-carousel'];

ContentCarousel.propTypes = {
    content: PropTypes.object,
    locale: PropTypes.string,
    urlConfig: PropTypes.object,
    internal_urls: PropTypes.array,
    imageQualityPercentage: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default ContentCarouselContainer;
