import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import ExternalRecommendationsList from './ExternalRecommendationsList/ExternalRecommendationsList';

interface ExternalRecommendationsWrapperProps {
    content: {
        title: string;
        href: string;
    }[];
    urlConfig: { [key: string]: object };
    locale: string;
    internal_urls: string[];
    messages: Record<string, unknown>;
}

const bem = new BEMHelper('ExternalRecommendationsWrapper');

const ExternalRecommendationsWrapper: React.FC<ExternalRecommendationsWrapperProps> = ({ content, urlConfig, locale, internal_urls, messages }) => {
    return (
        <div className={bem()}>
            <ExternalRecommendationsList
                items={content}
                urlConfig={urlConfig}
                locale={locale}
                internal_urls={internal_urls}
                messages={messages}
            />
        </div>
    );
};

export default React.memo(ExternalRecommendationsWrapper);
