import {
    PRODUCT_LISTING_FOUNDIT_FAILURE,
    PRODUCT_LISTING_FOUNDIT_REQUEST,
    PRODUCT_LISTING_FOUNDIT_SUCCESS,
    PRODUCT_LISTING_SEOPAGERECS_FAILURE,
    PRODUCT_LISTING_SEOPAGERECS_SUCCESS,
    PRODUCT_LISTING_SEOPAGERECS_REQUEST,
} from '../constants';

interface FounditContentResponse {
    ImageLinksEnabled?: boolean;
    Url?: string;
    Links?: {
        [key: string]: {
            AnchorText: string;
            DestinationUrl: string;
            ImageUrl: string;
            Order: number;
        }[];
    };
}

interface SeoPageContentResponse {
    Links: {
        anchor_text: string;
        url: string;
    }[];
}

export interface ExternalRecommendationsState {
    response: {
        body: Array<{
            title: string;
            href: string;
        }>;
        headers: object;
        status: number;
    } | null;
    loading: boolean;
    error: boolean;
}

const initialState: ExternalRecommendationsState = {
    response: null,
    loading: false,
    error: false,
};

interface FounditContentActionType {
    type: typeof PRODUCT_LISTING_FOUNDIT_REQUEST | typeof PRODUCT_LISTING_FOUNDIT_FAILURE | typeof PRODUCT_LISTING_FOUNDIT_SUCCESS;
    body?: FounditContentResponse;
    headers?: object;
    status?: number;
}

interface SeoPageContentActionType {
    type: typeof PRODUCT_LISTING_SEOPAGERECS_REQUEST | typeof PRODUCT_LISTING_SEOPAGERECS_FAILURE | typeof PRODUCT_LISTING_SEOPAGERECS_SUCCESS;
    body?: SeoPageContentResponse;
    headers?: object;
    status?: number;
}

const mapContentResponse = (responseBody?: any) => {
    if (responseBody?.Links) {
        const mainLinks = responseBody.Links['Main Links'];
        if (Array.isArray(mainLinks)) {
            return mainLinks.map(({ AnchorText, DestinationUrl }) => ({
                title: AnchorText,
                href: DestinationUrl,
            }));
        }
        const links = responseBody.Links;
        if (Array.isArray(links)) {
            return links.map(({ anchor_text, url }) => ({
                title: anchor_text,
                href: url,
            }));
        }
    }
    return responseBody;
};

export type CombinedActionType = FounditContentActionType | SeoPageContentActionType;

export default function reducer(state = initialState, action: CombinedActionType) {
    switch (action.type) {
        case PRODUCT_LISTING_FOUNDIT_REQUEST:
        case PRODUCT_LISTING_SEOPAGERECS_REQUEST:
            return {
                ...state,
                response: null,
                loading: true,
                error: false,
            };

        case PRODUCT_LISTING_FOUNDIT_FAILURE:
        case PRODUCT_LISTING_SEOPAGERECS_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                },
                error: true,
                loading: false,
            };

        case PRODUCT_LISTING_FOUNDIT_SUCCESS:
            return {
                ...state,
                response: {
                    body: mapContentResponse(action.body),
                    headers: action.headers,
                    status: action.status,
                },
                error: false,
                loading: false,
            };

        case PRODUCT_LISTING_SEOPAGERECS_SUCCESS:
            return {
                ...state,
                response: {
                    body: mapContentResponse(action.body),
                    headers: action.headers,
                    status: action.status,
                },
                error: false,
                loading: false,
            };

        default:
            return state;
    }
}
