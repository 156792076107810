import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@ynap/router';
import { SimpleAsyncButton } from '@ynap/button';
import { withOptimizely } from '@ynap/optimizely-utils';
import { filtersNotEqual, mergePrevFilters, getNextValidFilter, getDefaultActiveFilter, FILTER_REMOVED, CATEGORY_FILTER_ID } from './helpers';
import { SelectedFilterLabel } from '../../index';
import PLPAccordionSection from '../../PLPAccordionSection/PLPAccordionSection';
import { OptimizelyDecideOption } from '@optimizely/react-sdk';
import Markdown from 'markdown-to-jsx';

import BEMHelper from '@ynap/bem-helper';

const bem = new BEMHelper('FiltersWrapper');

const PRICE_FILTER_FACET_PREFIX = 'price';

const LTRDesigner = ({ children, ...props }) => (
    <bdo dir="ltr" {...props}>
        {children}
    </bdo>
);

class FiltersWrapper extends PureComponent {
    static propTypes = {
        trackSaleToggleEvent: PropTypes.func,
        children: PropTypes.node,
        className: PropTypes.string,
        filters: PropTypes.object.isRequired,
        messages: PropTypes.shape({
            productListing: PropTypes.shape({
                refine: PropTypes.func.isRequired,
                apply: PropTypes.func.isRequired,
                clearFilters: PropTypes.func.isRequired,
                saleToggle: PropTypes.function.isRequired,
            }).isRequired,
        }).isRequired,
        modalVisible: PropTypes.bool.isRequired,
        toggleFilter: PropTypes.func.isRequired,
        accordionAnimation: PropTypes.bool,
        generateListingUrl: PropTypes.func.isRequired,
        loading: PropTypes.shape({
            loadingRequest: PropTypes.bool.isRequired,
            pidOnly: PropTypes.bool,
        }),
        trackFilterEvent: PropTypes.func.isRequired,
        trackFavoritesEvent: PropTypes.func.isRequired,
        trackFilterToggleClick: PropTypes.func.isRequired,
        generateListingUrlFunction: PropTypes.func.isRequired,
        updateSelectedSchema: PropTypes.func.isRequired,
        getFilterGroup: PropTypes.func.isRequired,
        facetConfig: PropTypes.any.isRequired,
        filterCategoryByPath: PropTypes.string,
        designerSaleToggleData: PropTypes.shape({
            label: PropTypes.string,
            seo: PropTypes.shape({
                seoURLKeyword: PropTypes.string,
            }),
            pageType: PropTypes.string,
        }),
    };

    constructor(props) {
        super(props);
        this.state = {
            activeAccordionIdx: getDefaultActiveFilter(props.filters, this.props.facetConfig?.defaultActiveFilterIds),
            filters: props.filters,
            accordionAnimation: props.accordionAnimation,
        };
        this.topLevelFilter = props.filters[0]?.id || undefined;
        this.modalVisible = props.modalVisible;
        this.lastClickedFilterIdx = null;
        this.openNextAccordion = false;
    }

    setActiveAccordion = (id = null) => {
        this.setState({
            activeAccordionIdx: id,
        });
    };

    turnOffInitialAccordionAnimation = () => {
        this.state.accordionAnimation &&
            this.setState({
                accordionAnimation: false,
            });
    };

    setFilters = (filters) => new Promise((resolve) => this.setState({ filters }, resolve));

    /**
     * Sets the next valid filter as active
     * if last clicked filter was recorded
     */
    setNextAsActiveFilter = () => {
        if (this.lastClickedFilterIdx === null) {
            return;
        }

        const nextFilterId = getNextValidFilter(this.state.filters, this.lastClickedFilterIdx)?.id;
        nextFilterId && this.setActiveAccordion(nextFilterId);
        this.lastClickedFilterIdx = null;
    };

    isFirstLevelDefaultActiveAccordion = (id) => id === CATEGORY_FILTER_ID;

    /**
     * If defaultActiveFilterIds, sets the config default filter as active.
     * If on mobile (modalVisible) and defaultActiveAccordion is Categories (First Level Filter), close the accordion.
     */
    setDefaultActiveFilter = () => {
        const defaultActiveAccordionId = getDefaultActiveFilter(this.state.filters, this.props.facetConfig?.defaultActiveFilterIds);
        if (!defaultActiveAccordionId) return;
        this.modalVisible && this.isFirstLevelDefaultActiveAccordion(defaultActiveAccordionId)
            ? this.setActiveAccordion(null)
            : this.setActiveAccordion(defaultActiveAccordionId);
    };

    componentDidMount() {
        this.setDefaultActiveFilter();
    }

    async componentDidUpdate(prevProps) {
        // Not called on initial render so ensures that DOM is ready
        this.setFilterModalVisibility(this.props.modalVisible);

        const { filters = [] } = this.props;
        const prevFilters = prevProps.filters || [];

        if (filtersNotEqual(prevFilters, filters)) {
            await this.setFilters(mergePrevFilters(prevFilters, filters)).then(this.setNextAsActiveFilter);
            if (this.state.activeAccordionIdx === this.topLevelFilter) {
                this.setDefaultActiveFilter();
            }
        }
    }

    /**
     * Resets active accordions to none when modal becomes visible
     * has a bunch of:
     * TODO: remove once we have sitefurniture as a component - locks body if filter modal is visible
     * @param {boolean} visible
     */
    setFilterModalVisibility(visible) {
        // modal was not visible and now it is
        if (visible && !this.modalVisible) {
            this.setActiveAccordion(null);
        }
        this.modalVisible = visible;

        const toggle = (ele, className) => {
            const classes = ele.classList;
            visible ? classes.add(className) : classes.remove(className);
        };

        //TODO: remove once we have sitefurniture as a component - locks body if filter modal is visible
        const bodyClassName = bem('body', 'locked').split(' ')[1];
        toggle(document.body, bodyClassName);

        //TODO: remove once we have sitefurniture as a component - locks main if fitler modal is visible
        const mainWrapper = document.querySelector('main');
        if (mainWrapper) {
            const mainClassName = bem('main', 'locked').split(' ')[1];
            toggle(mainWrapper, mainClassName);
        }

        //TODO: remove once we have sitefurniture as a component - toggles livechat visibility if filter modal is visible so it doesn't overlap with apply
        const LPMcontainer = document.querySelector('.LPMcontainer');
        if (LPMcontainer) {
            const livechatClassName = bem('livechat', 'invisible').split(' ')[1];
            toggle(LPMcontainer, livechatClassName);
        }

        //TODO: remove once we have sitefurniture as a component - toggles search bar visibility over open filter modal on tablet
        const SFSearch = document.querySelector('.sf-search');
        if (SFSearch) {
            const SearchToolbarClassName = bem('searchToolbar');
            toggle(SFSearch, SearchToolbarClassName);
        }
    }

    /**
     * renders each filter accordion
     */
    renderFiltersAccordion() {
        const {
            messages,
            accordionAnimation,
            updateSelectedSchema,
            getFilterGroup,
            filterCategoryByPath,
            loading: { loadingRequest, pidOnly },
            facetConfig,
            generateListingUrlFunction,
            trackFilterEvent,
            trackFavoritesEvent,
            trackFilterToggleClick,
            trackDesignersSearchFocus,
            trackDesignersSearchEntry,
            useIdentifier,
            optimizely,
        } = this.props;

        const keys = ['web00097_pricefilter_PLP_Desktop', 'web00208_pricefilter_PLP_mobile'];

        const experiments = optimizely?.userContext?.decideForKeys(keys, [OptimizelyDecideOption.DISABLE_DECISION_EVENT]) || {};

        const enabledExperiments = Object.values(experiments)?.filter((exp) => exp.enabled === true);
        const { variationKey = '' } = enabledExperiments[0] || {};

        return (
            this.state.filters &&
            this.state.filters.map((ifilter, idx) => {
                const isPriceFilter = ifilter?.id?.indexOf(PRICE_FILTER_FACET_PREFIX) !== -1;
                const filter = ['v1_slider', 'v2_customer_defined'].includes(variationKey) && isPriceFilter ? ifilter.variant : ifilter;
                const FilterGroupComponent = getFilterGroup(filter.type, filter.id, facetConfig, variationKey, optimizely);

                if (FilterGroupComponent) {
                    const heading = (
                        <SelectedFilterLabel
                            id={filter.id}
                            filterName={filter.label}
                            appliedLabels={filter.appliedLabels}
                            messages={messages}
                            newValueAnimation={filter.status !== FILTER_REMOVED}
                        />
                    );

                    const onFilterClick =
                        filter.queryKey === facetConfig?.clickOpenNext
                            ? () => {
                                  this.lastClickedFilterIdx = idx;
                              }
                            : null;

                    const FilterComponent = (
                        <FilterGroupComponent
                            onClick={onFilterClick}
                            filter={filter}
                            generateListingUrl={generateListingUrlFunction()}
                            trackFilterEvent={trackFilterEvent}
                            trackFavoritesEvent={trackFavoritesEvent}
                            trackFilterToggleClick={trackFilterToggleClick}
                            messages={messages}
                            updateSelectedSchema={updateSelectedSchema}
                            filterBySeoPath={filterCategoryByPath}
                            facetConfig={facetConfig}
                            clearAllParamsOnAll={idx === 0}
                            trackDesignersSearchFocus={trackDesignersSearchFocus}
                            trackDesignersSearchEntry={trackDesignersSearchEntry}
                            useIdentifier={useIdentifier}
                            variationKey={variationKey}
                        />
                    );

                    return (
                        <PLPAccordionSection
                            key={filter.id}
                            uniqueId={filter.id}
                            heading={heading}
                            content={FilterComponent}
                            loading={loadingRequest && !pidOnly}
                            active={this.state.activeAccordionIdx === filter.id}
                            status={filter.status}
                            accordionAnimation={accordionAnimation}
                            onClickClose={() => this.setActiveAccordion(null)}
                            onClickOpen={() => {
                                this.setActiveAccordion(filter.id);
                                this.turnOffInitialAccordionAnimation();
                            }}
                        />
                    );
                }
            })
        );
    }

    getFilters() {
        const {
            messages,
            modalVisible,
            toggleFilter,
            generateListingUrlFunction,
            loading: { loadingRequest, pidOnly },
            designerSaleToggleData,
            facetConfig,
            trackSaleToggleEvent,
            country,
        } = this.props;
        const { filters } = this.state;

        const loadingModifier = loadingRequest ? 'loading' : '';
        const modalVisibleClass = modalVisible ? 'visible' : null;
        const modalLockedClass = modalVisible ? 'locked' : '';
        const accordionAnimation = this.state.accordionAnimation ? 'accordionAnimation' : '';
        const activeAccordionModifier = this.state.activeAccordionIdx ? 'active' : '';

        const isFilterApplied =
            filters && filters.length > 0
                ? filters.some((f) => {
                      return f.appliedValues && f.appliedValues.length > 0 && f.appliedValues[0] !== undefined;
                  })
                : false;

        const filterAppliedModifier = isFilterApplied ? 'activeClearBtn' : '';

        const saleToggleModifier = designerSaleToggleData?.pageType === 'DESIGNER' ? 'toggleSale' : '';

        const apply = messages.productListing.apply();

        const hasDefaultActiveFilters = facetConfig?.defaultActiveFilterIds?.length > 0;
        const displayFilterButtons = !(hasDefaultActiveFilters && this.state.activeAccordionIdx === CATEGORY_FILTER_ID);

        return (
            <nav className={bem('wrapper', [loadingModifier, modalVisibleClass])}>
                <div className={bem('container')}>
                    <div className={bem('titleContainer')}>
                        <div className={bem('title')}>{messages.productListing.refine()}</div>
                        <button
                            className={bem('closeButton')}
                            type="button"
                            onClick={() => {
                                toggleFilter(false, 'close');
                                this.turnOffInitialAccordionAnimation();
                            }}
                        />
                    </div>
                    <div className={bem('content', [modalLockedClass])}>
                        <div className={bem('accordionContainer', [activeAccordionModifier, accordionAnimation])}>{this.renderFiltersAccordion()}</div>
                        {designerSaleToggleData?.seo && !(loadingRequest && !pidOnly) && (
                            <Link
                                className={bem('designerSaleToggle', [activeAccordionModifier, accordionAnimation])}
                                onClick={() => {
                                    trackSaleToggleEvent(
                                        messages.productListing.saleToggle({
                                            country: country?.toUpperCase(),
                                            pageType: designerSaleToggleData.pageType,
                                            designerName: designerSaleToggleData.label || '',
                                        }),
                                        designerSaleToggleData.seo.seoURLKeyword,
                                    );
                                    toggleFilter(false, 'submit');
                                    this.turnOffInitialAccordionAnimation();
                                }}
                                to={generateListingUrlFunction()({
                                    pathname: designerSaleToggleData.seo.seoURLKeyword,
                                    relativeToBasePath: true,
                                    clearParamsByKey: ['pageNumber', 'facet', 'addCategoryId'],
                                })}
                            >
                                <span className={bem('designerSaleToggle', ['linkDecoration', saleToggleModifier])}>
                                    {/* Decorate the designer with MD bold tags so we can override it here by adding the correct
                                        <bdo> tags and preserve the correct text direction for the language. This saves us having
                                        to break up the sentence in the template, and avoid using dangerouslySetInnerHTML. */}
                                    <Markdown data-testid="saleToggle" options={{ overrides: { strong: LTRDesigner } }}>
                                        {messages.productListing.saleToggle({
                                            country: country?.toUpperCase(),
                                            pageType: designerSaleToggleData.pageType,
                                            designerName: `**${designerSaleToggleData.label || ''}**`,
                                        })}
                                    </Markdown>
                                </span>
                            </Link>
                        )}
                    </div>
                    {displayFilterButtons && (
                        <div className={bem('button')}>
                            {filters && (
                                <Link
                                    className={bem('clearFilters', [filterAppliedModifier, activeAccordionModifier])}
                                    to={generateListingUrlFunction()({
                                        pathname: filters[0]?.value,
                                        relativeToBasePath: true,
                                        clearParamsByKey: ['pageNumber', 'facet', 'addCategoryId'],
                                    })}
                                >
                                    {messages.productListing.clearFilters()}
                                </Link>
                            )}

                            <SimpleAsyncButton
                                containerClass={bem('apply')}
                                copy={apply}
                                onClick={() => {
                                    toggleFilter(false, 'submit');
                                    this.turnOffInitialAccordionAnimation();
                                }}
                                loading={loadingRequest}
                                type="primary"
                            />
                        </div>
                    )}
                </div>
            </nav>
        );
    }

    render() {
        const { className } = this.props;

        return <aside className={bem(null, null, className)}>{this.getFilters()}</aside>;
    }
}

export default withOptimizely(FiltersWrapper);
