import { getPageInfo, safeBuildEventObj, getBadgeValueFromIdentifier } from '@ynap/analytics';
import { performance } from '@ynap/clientside-monitoring';
import { plpSelectors } from '../../redux/product-listing-state';
import { useCategoryIdentifier } from '../';

const { getCategoryLabelFromIdentifier } = plpSelectors;

const _pdpPageAnalytics = async ({ product, trackingCategories, location, appState, config }) => {
    const timing = performance.intTiming.collectSpaIntTime();

    return {
        event: 'trackPage',
        page: getPage({ product, trackingCategories, location, appState, config, timing }),
        product: [getProduct({ product, trackingCategories, location, config })],
    };
};

export const getPage = ({ product, trackingCategories, location, appState, config, timing }) => {
    const {
        designerName,
        name,
        colourPartNumber,
        recommendationsToFetch,
        colourSwatches,
        visible,
        soldOutOnline,
        videoViews,
        tracking,
        badges = [],
        analyticsAttributes,
    } = product;
    const { language, country, prevPath } = appState;
    const { identifierToLabel = false, useIdentifier = false } = config?.head || {};
    const productName = tracking?.name || name;
    let featuresList = [];

    recommendationsToFetch?.map((rec) => {
        if (rec === 'WIW_ProductRec') {
            featuresList.push('wiw');
        } else if (rec === 'YMAL_ProductRec') {
            featuresList.push('ymal');
        }
    });

    if (colourSwatches.length > 1) {
        featuresList.push('cs');
    }

    if (videoViews) {
        featuresList.push('vid');
    }

    const pageName = `product - ${designerName} - ${productName} - ${colourPartNumber}`;
    const getCategoryFunc = useIdentifier ? useCategoryIdentifier : getCategoryLabelFromIdentifier;

    const analyticsBadges = useIdentifier
        ? Object.keys(badges).reduce((acc, key) => {
              if (badges[key]) {
                  acc[key] = getBadge(badges[key]);
              }
              return acc;
          }, {})
        : badges;

    const gender = analyticsAttributes?.gender;

    return {
        pageInfo: getPageInfo({ location, language, country, deviceType: 'website', pageName, prevUrl: prevPath, gender }),
        category: {
            pageType: soldOutOnline ? 'product details page - sold out' : 'product details page',
            primaryCategory: 'ecommerce',
            subCategory1: 'product page',
            subCategory2: designerName.toLowerCase(),
            subCategory3: getCategoryFunc(trackingCategories?.[0], identifierToLabel),
            subCategory4: getCategoryFunc(trackingCategories?.[1], identifierToLabel),
        },
        attributes: {
            businessName: config.global.application.brandId.toUpperCase(),
            currency: appState.currencyCode.toLowerCase(),
            featuresList,
            video: null, //TODO
            productVisibility: visible ? 'visible' : 'invisible', //TODO add eip / sale visibility
            eip: null, //TODO
            timing,
            badges: analyticsBadges,
        },
    };
};

export const getProduct = ({ product, trackingCategories, location, config }) => {
    const {
        designerName,
        name,
        colourPartNumber,
        price,
        sizeConversion,
        sizeMeasurement,
        skus,
        firstVisibleDate,
        designerIdentifier,
        identifier,
        analyticsAttributes,
        shippingRestricted,
        onSale,
        tracking,
        preOrder = false,
    } = product;
    const { currency, discountPercentage, finalPrice, originalPrice, minimumPrice } = price;
    const { pathname } = location;
    const { identifierToLabel = false, useIdentifier = false } = config?.head || {};
    const productName = tracking?.name || name;

    const productStock = skus.reduce((acc, sku) => {
        const stockBadge = sku.badges && sku.badges.find((badge) => badge.type === 'STOCK');

        const skuInfo = {
            sku: sku.partNumber,
            size: sku.size && sku.size.labelSize,
            stock: stockBadge ? stockBadge.key : 'IN STOCK',
        };
        return [...acc, skuInfo];
    }, []);

    const hasSizeAndFit = (sizeConversion && sizeConversion.length) > 1 || (sizeMeasurement && sizeMeasurement.length) > 1;
    const getCategoryFunc = useIdentifier ? useCategoryIdentifier : getCategoryLabelFromIdentifier;
    const gender = analyticsAttributes?.gender;

    return {
        productInfo: {
            productID: colourPartNumber,
            productName,
            manufacturer: analyticsAttributes?.designerNameEN || designerName,
            manufacturerID: designerIdentifier,
            productURL: pathname,
            ...(gender ? { gender } : {}),
        },
        category: {
            primaryCategory: getCategoryFunc(trackingCategories?.[0], identifierToLabel),
            subCategory1: getCategoryFunc(trackingCategories?.[1], identifierToLabel),
            subCategory2: getCategoryFunc(trackingCategories?.[2], identifierToLabel),
        },
        price: {
            currency: currency.label,
            basePrice: originalPrice || finalPrice,
            priceWithDiscountTax: finalPrice,
            discount: discountPercentage || 0,
            bestPrice: minimumPrice,
        },
        attributes: {
            productStock,
            firstVisibleDate,
            hasSizeAndFit,
            colour: identifier,
            saleFlag: onSale ? 'on sale' : 'full price',
            preOrderFlag: preOrder,
            ...analyticsAttributes,
            shippingRestricted: !!shippingRestricted, // can be true or undefined, so we force boolean
            badges: analyticsAttributes.badges ? [useIdentifier ? getBadge(analyticsAttributes.badges[0]) : analyticsAttributes.badges[0]] : [],
        },
    };
};

export const getBadge = (badge) => ({
    ...badge,
    label: getBadgeValueFromIdentifier(badge.key),
});

export const pdpPageAnalytics = (args) => safeBuildEventObj(_pdpPageAnalytics, args);
