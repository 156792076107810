import { stringify, parse, parseUrl } from 'query-string';
import {
    MultiSelectFilterGroup,
    DesignerFilterGroup,
    SingleSelectFilterGroup,
    SchemaFilterGroup,
    PriceFilterGroup,
    PriceCustomerDefined,
} from '../../components/product-listing-page';

const PRICE_FILTER_FACET_PREFIX = 'price';

// given the current query string add/remove (toggle) the passed parameter(s)
export const getNewQueryParams = (param, oldQueryParams) => {
    const key = Object.keys(param)[0];
    let values = [];

    if (param[key] instanceof Array) {
        values = param[key];
    } else {
        values.push(param[key]);
    }

    values.map((value) => {
        // if we have the key in the current query params
        if (oldQueryParams[key]) {
            // if the key and value match, remove.
            if (oldQueryParams[key] === value) {
                delete oldQueryParams[key];
            } else if (oldQueryParams[key] instanceof Array) {
                if (oldQueryParams[key].filter((filterValue) => filterValue === value).length > 0) {
                    oldQueryParams[key] = oldQueryParams[key].filter((filterValue) => filterValue !== value);
                } else {
                    oldQueryParams[key].push(value);
                }
            } else {
                oldQueryParams[key] = [oldQueryParams[key], value];
            }
        } else {
            oldQueryParams[key] = value;
        }
    });

    return oldQueryParams;
};

export const generateListingUrl = (newCustomLocationObject, currentRouterLocationObject, currentRouterMatchObject = null, pathParam) => {
    const { pathname: currentLocationString, search: currentSearchString = '' } = currentRouterLocationObject;
    const {
        pathname = null,
        toggleParams = null,
        clearParamsByKey = null,
        preserveParamsByKey = [],
        preserveAndAddToParamsByKey = [],
        relativeToBasePath = null,
        replaceParams = null,
    } = newCustomLocationObject;

    let locationString = currentLocationString;
    let oldQueryParams = parse(currentSearchString);
    const preservedParams = ['previewDate', ...preserveParamsByKey, ...preserveAndAddToParamsByKey].reduce(
        (params, key) => Object.assign(params, { [key]: oldQueryParams[key] }),
        {},
    );

    // Expose default router api
    if (pathname) {
        if (relativeToBasePath && currentRouterMatchObject) {
            const currentMatchParamsLength = currentRouterMatchObject.params[pathParam].length;

            const currentMatchUrl = currentRouterMatchObject.url;
            const currentMatchUrlLength = currentMatchUrl.length;

            const trimUrlLength = currentMatchUrlLength - currentMatchParamsLength;

            // -1 to remove extra /
            const basePath = currentMatchUrl.substring(0, trimUrlLength - 1);

            locationString = basePath + pathname;
        } else {
            locationString = pathname;
        }

        oldQueryParams = {};
    }

    if (toggleParams) {
        oldQueryParams = getNewQueryParams(toggleParams, oldQueryParams);
    }
    clearParamsByKey &&
        clearParamsByKey.forEach((paramKey) => {
            delete oldQueryParams[paramKey];
        });
    replaceParams && Object.keys(replaceParams).forEach((key) => (oldQueryParams[key] = replaceParams[key]));
    oldQueryParams = preserveAndAddToParamsByKey ? deepMerge(preservedParams, oldQueryParams) : { ...oldQueryParams, ...preservedParams };

    const searchString = stringify(oldQueryParams);
    const searchObj = searchString ? { search: '?' + searchString } : {};

    return { pathname: locationString, ...searchObj };
};

export const getFilterGroup = (filterType, filterId, facetConfig, variationKey, optimizely) => {
    if (filterId && filterId?.indexOf(PRICE_FILTER_FACET_PREFIX) !== -1) {
        if (facetConfig?.togglePriceFilterSlider || variationKey === 'v1_slider') return PriceFilterGroup;
        if (variationKey === 'v2_customer_defined') return PriceCustomerDefined;
        if (!optimizely?.isUserReady) return false;
        return MultiSelectFilterGroup;
    }
    // todo TYS-870 remove FLAT_SELECT after ton dual running
    const filterMapping = {
        MULTI_SELECT: {
            Brand: DesignerFilterGroup,
            Default: MultiSelectFilterGroup,
            Category_0: null,
            // This is here because currently Filter Colour errors in WCS
            'Brand Colour': MultiSelectFilterGroup,
            'Filter Colour': MultiSelectFilterGroup,
            'Brand Size': null,
            Military_BR: null,
        },
        SCHEMA_SELECT: {
            Default: SchemaFilterGroup,
            SIZE_SCHEME_GLO: MultiSelectFilterGroup,
            SIZE_SCHEME_ONESIZE: MultiSelectFilterGroup,
        },
        SINGLE_SELECT: {
            BrandSize: SingleSelectFilterGroup,
            Default: SingleSelectFilterGroup,
        },
    };

    if (filterMapping[filterType]) {
        return Object.prototype.hasOwnProperty.call(filterMapping[filterType], filterId)
            ? filterMapping[filterType][filterId]
            : filterMapping[filterType].Default;
    } else {
        return MultiSelectFilterGroup;
    }
};

export const dataFetched = (pls, pageKey, allowList) => {
    const filteredpageKey = filterUrl(pageKey, allowList);
    return pls?.find((pl) => {
        const filteredKey = filterUrl(pl?.key, allowList);
        return filteredKey == filteredpageKey || decodeURI(filteredKey) == filteredpageKey;
    })
        ? true
        : false;
};

export const shouldRefetch = ({ url, newUrl, pls, pageKey, allowList }) => {
    const filteredUrl = filterUrl(url, allowList);
    const filteredNewUrl = filterUrl(newUrl, allowList);

    if (filteredUrl === filteredNewUrl) {
        return false;
    }
    const parsedUrl = parseUrl(filteredUrl);

    const newParsedUrl = parseUrl(filteredNewUrl);

    if (parsedUrl.url !== newParsedUrl.url) {
        return true;
    }
    if (!dataFetched(pls, pageKey, allowList)) {
        return true;
    }
    const keys = Object.keys(parsedUrl.query);
    const newKeys = Object.keys(newParsedUrl.query);

    const sameSize = keys.length === newKeys.length;
    return keys.length >= newKeys.length ? compare(parsedUrl.query, newParsedUrl.query, sameSize) : compare(newParsedUrl.query, parsedUrl.query, sameSize);
};

const deepMerge = (obj1, obj2) => {
    let obj = { ...obj1, ...obj2 };
    Object.entries(obj)?.forEach(([key, value]) => {
        const values1 = obj1[key] ? Object.values(obj1[key]) : [];
        const values2 = obj2[key] ? Object.values(obj2[key]) : [];
        obj[key] = Array.isArray(value) ? values2.concat(values1.filter((item) => values2.indexOf(item) < 0)) : value;
    });
    return obj;
};

const compare = (query, newQuery, sameSize) => {
    const diff = Object.keys(query).filter((k) => query[k] !== newQuery[k]);
    if (diff.length === 1 && diff[0] != 'pageNumber') {
        return true;
    }
    if (diff.length > 1) {
        return true;
    }
    if (sameSize && !newQuery.pageNumber) {
        return true;
    }
    return false;
};

const filterUrl = (url = '', allowList = []) => {
    const parsedUrl = parseUrl(url);
    const filteredQueries = allowList.reduce((acc, query) => {
        const validQuery = parsedUrl.query[query];
        return validQuery ? `${acc}${acc === '' ? '?' : '&'}${query}=${validQuery}` : `${acc}`;
    }, '');

    return parsedUrl.url + filteredQueries;
};

export const triggerWebpushNotification = (analyticsData) => {
    return window?.SF?.triggerWebpushNotification?.({
        visible: true,
        analyticsData,
    });
};
