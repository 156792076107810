import getCanonicalSEOPath from './getCanonicalSEOPath';
export const getSelectedProductSEO = (product) => {
    return product && product.seo ? product.seo : {};
};

export const getProductSEO = (product, selectedProductColour, selectedProductSize, pdpUrlTemplate, locale) => {
    const productSEO = getSelectedProductSEO(product);
    const selectedProductColourSEO = getSelectedProductSEO(selectedProductColour);

    const selectedProductName = selectedProductColour?.shortDescription || product.name;
    const selectedProductColourTitle = `${selectedProductColour.label} ${selectedProductName} | ${product.designerName}`;
    const selectedProductColourMetaDescription = selectedProductColour.editorialDescription.replace(/<[^>]*>/g, '');
    //partNumber and legacyId are used as key to determine which seo data to use

    return {
        productSEO: {
            title: productSEO.title ?? '',
            metaDescription: productSEO.metaDescription ?? '',
            metaKeyword: productSEO.metaKeyword ?? '',
            seoURLKeyword: productSEO.seoURLKeyword ?? '',
            partNumber: product.partNumber,
            legacyId: product.legacyId,
            alternateText: productSEO.alternateText ?? '',
        },
        selectedProductColourSEO: {
            title: selectedProductColourSEO.title ?? selectedProductColourTitle,
            metaDescription: selectedProductColourSEO.metaDescription ?? selectedProductColourMetaDescription ?? '',
            metaKeyword: selectedProductColourSEO.metaKeyword ?? productSEO.metaKeyword ?? '',
            seoURLKeyword: selectedProductColourSEO.seoURLKeyword ?? '',
            fullTitle: Boolean(selectedProductColourSEO.title),
            partNumber: selectedProductColour.partNumber,
            legacyId: selectedProductColour.legacyId,
        },
        selectedProductSizeSEO: {
            //title and description from product colour, keyword from product
            title: selectedProductColourTitle,
            metaDescription: selectedProductColourMetaDescription,
            metaKeyword: productSEO.metaKeyword ?? '',
            partNumber: selectedProductSize.partNumber,
            legacyId: selectedProductSize.legacyId,
        },
        //canonical seo data is always set to selected productColour SEO
        canonicalSEOPath: getCanonicalSEOPath(
            pdpUrlTemplate,
            locale,
            selectedProductColourSEO.seoURLKeyword ? selectedProductColourSEO.seoURLKeyword : `/${selectedProductColour.partNumber}`,
        ),
    };
};
