const getExternalRecommendationsPageTemplate = (pageType) => {
    switch (pageType) {
        case 'listing page':
            return 'Category';
        case 'product details page':
            return 'Product';
        case 'search results page':
            return 'Search';
        default:
            return 'Category';
    }
};

const getExternalRecommendationsLinkContainer = (pageType) => {
    if (pageType === 'product details page') return 'productwidget-product';
    return 'Main-Links';
};

const getExternalRecommendationsSubCategory = (item, linkPosition, totalLinks, pageType) => {
    const { title, href: targetPage } = item;
    const sourcePage = window?.location?.href;
    const pageTemplate = getExternalRecommendationsPageTemplate(pageType);
    const linkContainer = getExternalRecommendationsLinkContainer(pageType);

    return [sourcePage, pageTemplate, linkContainer, linkPosition, totalLinks, title, targetPage].join('|');
};

export const trackExternalRecommendationsModuleClick = ({ analytics, item, totalLinks, linkPosition }) => ({
    event: 'links-click',
    effect: 'click on External Recommendations link-clicks',
    category: {
        primaryCategory: 'External Recommendations',
        subCategory: getExternalRecommendationsSubCategory(item, linkPosition, totalLinks, analytics.page?.category?.pageType),
    },
});
