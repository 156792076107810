import React from 'react';
import PropTypes from 'prop-types';
import { createComponent } from '@ycos/fela';
import Skeleton from '@ynap/skeleton';
import { Gutter, ProductImage } from './shared';
import { useAppState, withConfig } from '@ynap/immutables-provider';

const additionalSkeletonStyle = {
    description: {
        ':last-child': {
            width: '30%',
        },
    },
};
const Wrapper = createComponent('Wrapper', ({ theme }) => ({
    width: '100%',
    padding: `${2 * theme.skeleton.wrapperVerticalPadding}px ${2 * theme.skeleton.wrapperHorizontalPadding}px`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    'screen-medium': {
        padding: `${2 * theme.skeleton.wrapperVerticalPadding}px ${4 * theme.skeleton.wrapperHorizontalPadding}px`,
    },
    'screen-large': {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridColumnGap: `${3 * theme.spacingMultiplier}px`,
        padding: `${4 * theme.spacingMultiplier}px ${4 * theme.spacingMultiplier}px`,
    },
}));

const WrapperFB = createComponent('Wrapper', ({ theme }) => ({
    width: '100%',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    'screen-large': {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridColumnGap: `${3 * theme.spacingMultiplier}px`,
    },
}));

const Image = createComponent('Image', ({ theme }) => ({
    ':nth-of-type(2)': {
        display: 'none',
    },
    flexBasis: '45%',
    flexGrow: '1',
    'screen-medium': {
        flexGrow: theme.skeleton.carouselTabletFlexGrow,
        width: theme.skeleton.carouselTabletWidth,
        ':nth-of-type(2)': {
            display: 'block',
            marginLeft: `${theme.spacingMultiplier}px`,
        },
    },
    'screen-large': {
        flexGrow: '1',
        width: '100%',
        gridColumn: '2/span 5',
        ':nth-of-type(2)': {
            display: 'none',
        },
    },
}));

const ImageFB = createComponent('Image', ({ theme }) => ({
    ':nth-of-type(2)': {
        display: 'none',
    },
    flexBasis: '45%',
    flexGrow: '1',
    'screen-medium': {
        flexGrow: theme.skeleton.carouselTabletFlexGrow,
        width: theme.skeleton.carouselTabletWidth,
        ':nth-of-type(2)': {
            display: 'block',
            marginLeft: `${theme.spacingMultiplier}px`,
        },
    },
    'screen-large': {
        flexGrow: '1',
        width: '100%',
        gridColumn: '1/span 6',
        ':nth-of-type(2)': {
            display: 'none',
        },
    },
}));

const Pagination = createComponent('Pagination', ({ theme }) => ({
    width: '100%',
    display: theme.skeleton.paginationDisplay,
    justifyContent: 'center',
    paddingBottom: `${theme.spacingMultiplier}px`,
    'screen-large': {
        display: 'none',
    },
}));

const PaginationCircle = createComponent('PaginationCircle', ({ theme }) => ({
    flex: `0 0 ${theme.spacingMultiplier}px`,
    height: `${theme.spacingMultiplier}px`,
    borderRadius: '50%',
    width: `${theme.spacingMultiplier}px`,
    margin: `${theme.spacingMultiplier}px`,
    backgroundColor: theme.skeleton.color,
}));

const ProductInfo = createComponent('ProductInfo', ({ theme }) => ({
    margin: theme.skeleton.productInfoMargin,
    flexBasis: '100%',
    'screen-medium': {
        maxWidth: `${60 * theme.spacingMultiplier}px`,
    },
    'screen-large': {
        marginTop: '0',
        gridColumn: '8/span 4',
    },
}));

const ProductInfoFB = createComponent('ProductInfo', ({ theme }) => ({
    marginTop: `${8 * theme.spacingMultiplier}px`,
    flexBasis: '100%',
    'screen-medium': {
        maxWidth: `${60 * theme.spacingMultiplier}px`,
    },
    'screen-large': {
        gridColumn: '8/span 5',
    },
}));

const ProductDesigner = createComponent('ProductDesigner', ({ theme }) => ({
    marginBottom: `${2 * theme.spacingMultiplier}px`,
    width: '60%',
}));

const Price = createComponent('Price', ({ theme }) => ({
    width: '25%',
    marginBottom: `${2 * theme.spacingMultiplier}px`,
}));

const Name = createComponent('Name', ({ theme }) => ({
    width: '90%',
    marginBottom: `${2 * theme.spacingMultiplier}px`,
}));

const Colour = createComponent('Colour', ({ theme }) => ({
    width: '15%',
    marginBottom: `${4 * theme.spacingMultiplier}px`,
}));

const Description = createComponent('Description', ({ theme }) => ({
    marginBottom: `${2 * theme.spacingMultiplier}px`,
}));

export const SkeletonFullBleed = (productImage) => {
    return (
        <Gutter>
            <WrapperFB>
                {new Array(2).fill().map((v, i) => (
                    <ImageFB key={`Image${i}`}>
                        <ProductImage imageAR={productImage} />
                    </ImageFB>
                ))}
                <ProductInfoFB>
                    <ProductDesigner>
                        <Skeleton />
                    </ProductDesigner>
                    <Name>
                        <Skeleton />
                    </Name>
                    <Price>
                        <Skeleton />
                    </Price>
                    <Colour>
                        <Skeleton />
                    </Colour>
                    <Description>
                        <Skeleton count={5} additionalStyle={additionalSkeletonStyle.description} />
                    </Description>
                </ProductInfoFB>
                <Pagination>
                    {new Array(3).fill().map((v, i) => (
                        <PaginationCircle key={`Page${i}`} />
                    ))}
                </Pagination>
            </WrapperFB>
        </Gutter>
    );
};

export const SkeletonWrapper = (props) => {
    const { aspectRatio, pdp } = props.config;
    const productImage = aspectRatio.productImage;
    const { enabled, enabledIn } = pdp.fullBleedLayout || {};
    const country = useAppState((state) => state.country);
    const isfullBleedLayout = enabled && enabledIn?.includes(country.toUpperCase());
    if (isfullBleedLayout) return SkeletonFullBleed(productImage);

    return (
        <Gutter>
            <Wrapper>
                <ProductInfo>
                    <ProductDesigner>
                        <Skeleton />
                    </ProductDesigner>
                    <Name>
                        <Skeleton />
                    </Name>
                    <Price>
                        <Skeleton />
                    </Price>
                </ProductInfo>
                {new Array(2).fill().map((v, i) => (
                    <Image key={`Image${i}`}>
                        <ProductImage imageAR={productImage} />
                    </Image>
                ))}
                <Pagination>
                    {new Array(3).fill().map((v, i) => (
                        <PaginationCircle key={`Page${i}`} />
                    ))}
                </Pagination>
                <ProductInfo>
                    <Colour>
                        <Skeleton />
                    </Colour>
                    <Description>
                        <Skeleton count={5} additionalStyle={additionalSkeletonStyle.description} />
                    </Description>
                </ProductInfo>
            </Wrapper>
        </Gutter>
    );
};

SkeletonWrapper.propTypes = {
    config: PropTypes.shape({
        productImage: PropTypes.object.isRequired,
    }),
};

export const PDPSkeleton = withConfig((config) => config.head)(SkeletonWrapper);
export default PDPSkeleton;
