import { getPageInfo, safeBuildEventObj } from '@ynap/analytics';
import { performance } from '@ynap/clientside-monitoring';
import { trackProductReplacement } from '../../components/product-replacement/analytics';
import { plpSelectors } from '../../redux/product-listing-state';
import { useCategoryIdentifier } from '../';
import { parse } from 'query-string';

const { getCategoryLabelFromIdentifier } = plpSelectors;

export const _plpPageAnalytics = async ({
    sale = false,
    productLists,
    config,
    appState,
    location,
    categoryLabels,
    pageType,
    searchTerm,
    mainContent,
    selectedCategories,
    ...rest
}) => {
    const useIdentifier = config?.head?.useIdentifier;
    const productsTotal = productLists?.[0]?.products.length;
    const pageName = getPageName(categoryLabels, pageType, searchTerm, productsTotal, selectedCategories, config);
    const { language, country, prevPath } = appState;
    const timing = performance.intTiming.collectSpaIntTime();
    const gender = productLists?.[0]?.products?.find((p) => p.analyticsAttributes?.gender)?.analyticsAttributes?.gender;

    return {
        event: 'trackPage',
        page: {
            pageInfo: getPageInfo({ location, language, country, deviceType: 'website', pageName, prevUrl: prevPath, useIdentifier, gender }),
            category: getCategory(categoryLabels, pageType),
            attributes: getAttributes({ sale, config, appState, mainContent, timing, ...rest }),
        },
        listing: getListing({ productLists, pageType, config }),
    };
};

export const getPageName = (categoryLabels, pageType = '', searchTerm, productsTotal, selectedCategories, config) => {
    const { identifierToLabel = false, useIdentifier = false } = config?.head || {};

    const pageName = categoryLabels?.join(' - ') || '';
    const pageNameCategories = selectedCategories?.map((item, index) => {
        const isTopLevelCategory = index === 0;
        return getCategoryLabelFromIdentifier(item, identifierToLabel, pageType, isTopLevelCategory, useIdentifier);
    });
    // removes any duplicate & empty page categories
    const additionalPageNames = pageNameCategories?.filter((item) => item !== '' && !pageName.includes(item?.toLowerCase()));
    const allPageNameCategories = additionalPageNames?.length ? `${pageName} - ${additionalPageNames.join(' - ').toLowerCase()}` : pageName;
    // removes any trailing spaces or '-'
    const trimmedPageNameCategories = allPageNameCategories?.trim().replace(/-$/, '').trim();

    switch (pageType) {
        case 'MARK_DOWN':
        case 'MARK_DOWN_DESIGNER':
            //sale page categories don't include 'sale' but prepend for page name
            return `sale - ${pageName}`;
        case 'SEARCH':
            return `search - ${productsTotal > 0 ? searchTerm : 'no results'}`;
        default:
            return trimmedPageNameCategories;
    }
};

export const getCategory = (categoryLabels, pageType = '') => {
    return pageType === 'SEARCH'
        ? {
              pageType: 'search results page',
              primaryCategory: 'search',
              subCategory1: 'search',
          }
        : {
              pageType: 'listing page',
              primaryCategory: 'ecommerce',
              subCategory1: categoryLabels?.[0] || '',
              subCategory2: categoryLabels?.[1] || '',
              subCategory3: categoryLabels?.[2] || '',
              subCategory4: categoryLabels?.[3] || '',
          };
};

const firstCharLowerCaseAndCleanSpaces = (filterId) => {
    return filterId
        .toLowerCase()
        .replace('ukme', ' ')
        .replace(/[ _]+/g, '-')
        .split('-')
        .map((key, id) => (id === 0 ? key : key[0]?.toUpperCase() + key?.substr(1)))
        .join('');
};

export const getFilters = ({ filters, useIdentifier }) => {
    let pageFilters = {};
    let pageFilterIds = {};
    let selectedFilters = {};
    let selectedFilterIds = {};

    filters.map((f) => {
        const { label: filterLabel, id: filterId, values, queryKey, type } = f;
        const _filterId = firstCharLowerCaseAndCleanSpaces(filterId);
        const _filterLabel = firstCharLowerCaseAndCleanSpaces(filterLabel);
        values.map(({ value, label, isApplied, identifier }) => {
            const trackingFilterValue = getTrackingFilterValue({ useIdentifier, identifier, queryKey, label, filterId, value, type });
            const filterFilterGroupValue = useIdentifier ? _filterId : _filterLabel;
            pageFilters[filterFilterGroupValue] = pageFilters[filterFilterGroupValue]
                ? [...pageFilters[filterFilterGroupValue], trackingFilterValue]
                : [trackingFilterValue];
            pageFilterIds[_filterId] = pageFilterIds[_filterId] ? [...pageFilterIds[_filterId], value] : [value];
            if (isApplied) {
                selectedFilters[filterFilterGroupValue] = selectedFilters[filterFilterGroupValue]
                    ? [...selectedFilters[filterFilterGroupValue], trackingFilterValue]
                    : [trackingFilterValue];
                selectedFilterIds[_filterId] = selectedFilterIds[_filterId] ? [...selectedFilterIds[_filterId], value] : [value];
            }
        });
    });

    return {
        pageFilters,
        pageFilterIds,
        selectedFilters,
        selectedFilterIds,
    };
};

export const getTrackingFilterValue = ({ useIdentifier, identifier, queryKey, label, filterId, type }) => {
    if (!useIdentifier) {
        return label;
    }
    if (filterId === 'CATEGORIES' || filterId?.includes('UKME__')) {
        if (filterId.includes('UKME__')) {
            const category = filterId.substring(6).replace(/_/g, ' ').toLowerCase();
            return identifier.replace(/_/g, ' ').replace(category, '').trim().replace(/ /g, '_');
        } else {
            return identifier;
        }
    }
    if (queryKey === 'categoryId' || queryKey === 'addCategoryId') {
        return identifier?.split('__')?.[1];
    }
    if (filterId === 'Brand') return label;
    if (type === 'SCHEMA_SELECT') {
        return label;
    }
    if (filterId === 'Filter_Fabric') {
        return identifier.substring(14); //remove prefix Filter_Fabric_
    }
    return identifier;
};

export const getAttributes = ({ paginationData, orderBy = [], appState, filters, config, mainContent, timing, carouselContent, footerContent }) => {
    const sortOrder = orderBy.find((ordering) => ordering.selected);
    const useIdentifier = config?.head?.useIdentifier;
    return {
        resultsNum: paginationData.recordSetTotal,
        pageNum: paginationData.pageNumber,
        view: {
            number: paginationData.recordSetCount,
            type: 'product', // we do not offer option to change to outfit view
            columnNumber: null,
            sortOrder: sortOrder ? sortOrder.labelEN : 'default',
            sortOrderId: sortOrder?.key,
            ...getFilters({ filters, useIdentifier }),
        },
        currency: appState.currencyCode.toLowerCase(),
        businessName: config.global.application.brandId.toUpperCase(),
        ...getSiteMerchandising(config.head.plp.analyticsSiteMerchByUrl, mainContent, carouselContent, footerContent),
        timing,
    };
};

export const getSiteMerchandisingDataByUrl = (url) => {
    const queryParams = url?.split?.('?')[1];
    const parsedQueryParams = parse(queryParams);
    const cmspQueryParam = parsedQueryParams.cm_sp || '';

    if (!cmspQueryParam) return null;

    const cmspSections = cmspQueryParam.split('-_-');
    const placement = cmspSections[0];
    const position = cmspSections[1];
    const campaignName = cmspSections[2];
    const campaignStartDate = cmspSections[3];

    if (!placement || !position || !campaignName || !campaignStartDate) return null;
    return {
        position,
        placement,
        campaignName,
        campaignStartDate,
    };
};

const getSiteMerchandisingFromItems = (items, type = '') => {
    return (
        items?.reduce?.((accumulator, singleItem, itemIndex) => {
            const siteMerchandisingData = singleItem?.href && getSiteMerchandisingDataByUrl(singleItem.href);
            siteMerchandisingData && (siteMerchandisingData.modulePosition = `${type}${singleItem.modulePosition ?? itemIndex + 1}`);
            siteMerchandisingData && accumulator.push(siteMerchandisingData);
            return accumulator;
        }, []) || []
    );
};

const buildSiteMerchandising = (mainContent, carouselContent, footerContent) => {
    // To the carousel at the top
    const carouselContentResult = getSiteMerchandisingFromItems(carouselContent?.[0]?.items, 'carousel');

    // To the content on the grid (InList)
    const mainContentResult =
        mainContent?.[0]?.stickyTeaser?.map?.((stickyTeaser) => {
            const {
                item: { items = [] },
                position,
            } = stickyTeaser;

            // this applies the same modulePosition to all items, the assumption is there is only one item per row
            const itemsWithPosition = position ? items.map((item) => ({ ...item, modulePosition: position })) : items;

            return getSiteMerchandisingFromItems(itemsWithPosition, 'inlist');
        })?.[0] || [];

    // To the Exit gates on footer
    const footerContentResult = getSiteMerchandisingFromItems(footerContent?.[0]?.items, 'footer');

    return [...carouselContentResult, ...mainContentResult, ...footerContentResult];
};

export const getSiteMerchandising = (analyticsSiteMerchByUrl = false, mainContent, carouselContent, footerContent) => {
    // analyticsSiteMerchByUrl controls the way that page.attributes.siteMerchandising gets populated
    // if true, gets the data from the url queryparameter applied on CM "...?cm_sp=<position>-_-<placement>-_-<campaignName>-_-<campaignStartDate>"
    const siteMerchandising = analyticsSiteMerchByUrl
        ? buildSiteMerchandising(mainContent, carouselContent, footerContent)
        : trackProductReplacement(mainContent);
    return siteMerchandising && { siteMerchandising };
};

export const getProductItem = (
    { partNumber, designerName, name, designerIdentifier, price, masterCategory, onSale, analyticsAttributes, consideredBadge } = {},
    index,
    pageType,
    config,
) => {
    const { identifierToLabel = false, useIdentifier = false } = config?.head || {};

    const getCategoryFunc = useIdentifier ? useCategoryIdentifier : getCategoryLabelFromIdentifier;
    const enable = useIdentifier ? useIdentifier : identifierToLabel;
    const gender = analyticsAttributes?.gender;

    return {
        category: {
            primaryCategory: getCategoryFunc(masterCategory, enable, pageType, false),
            subCategory1: getCategoryFunc(masterCategory?.child, enable, pageType, false),
            subCategory2: getCategoryFunc(masterCategory?.child?.child, enable, pageType, false),
        },
        productInfo: {
            productID: `${partNumber}`,
            name: analyticsAttributes?.nameEN || name,
            designer: analyticsAttributes?.designerNameEN || designerName,
            designerID: designerIdentifier,
            ...(gender ? { gender } : {}),
        },
        attributes: {
            position: index + 1,
            stock: analyticsAttributes?.badge?.type === 'STOCK' ? analyticsAttributes.badge.key : 'IN_STOCK',
            discountPercent: price.discountPercentage ? price.discountPercentage : '0',
            saleFlag: !onSale ? 'full price' : 'on sale',
            colour: analyticsAttributes?.colour,
            season: analyticsAttributes?.season,
            department: analyticsAttributes?.department,
            preOrderFlag: analyticsAttributes?.preOrder,
            badges: analyticsAttributes?.badge && [analyticsAttributes.badge],
            sustainabilityFlag: consideredBadge ? true : false,
        },
        price: {
            currency: price?.currency?.label,
            baseFullPrice: price?.originalPrice,
            basePrice: price?.originalPrice || price?.finalPrice,
            basePriceGBP: null, // TODO
            discount: price?.discountPercentage || 0,
            discountPrice: price?.originalPrice ? price?.originalPrice - price?.finalPrice : 0,
            priceWithDiscountTax: price?.finalPrice,
            bestPrice: price?.minimumPrice,
        },
    };
};

export const getListing = ({ productLists, pageType, config }) => {
    if (!productLists || productLists.length === 0) {
        return {
            item: [],
        };
    }

    const mapped = productLists.reduce((acc, pl) => {
        return pl.products.length > 0 ? acc.concat(pl.products.map((product, index) => getProductItem(product, index, pageType, config))) : acc;
    }, []);

    return {
        item: mapped,
    };
};

export const plpPageAnalytics = (args) => safeBuildEventObj(_plpPageAnalytics, args);
