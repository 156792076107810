import { combineReducers } from '@ynap/reducer-utils';

import { default as listingReducer } from './responseReducer';
import { default as contentReducer } from './contentReducer';
import { default as selectedSchemas } from './selectedSchemas';
import { default as externalRecommendationsReducer } from './externalRecommendationsReducer';

export { listingReducer, selectedSchemas, externalRecommendationsReducer };

export default combineReducers({
    listing: listingReducer,
    selectedSchemas,
    content: contentReducer,
    externalRecommendations: externalRecommendationsReducer,
});
