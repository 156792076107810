import React from 'react';
import Swatch from '@ynap/swatch';
import BEMHelper from '@ynap/bem-helper';
import { Link } from 'react-router-dom';

const bem = new BEMHelper('Colours');

const MAX_COLOURS = 5;
const MIN_COLOURS = 1;

const Colours = (props) => {
    const { className, colours, loading = false, Wrapper = 'div', product, activeForm, features } = props;
    if (features?.hideColour) return null;
    if (loading) {
        return <div className={bem(null, 'loading', className)} />;
    }

    const selectedColour = colours?.find((c) => product?.partNumber === c.partNumber);
    const orderedColours = colours?.filter((c) => product?.partNumber !== c.partNumber && c.buyable) || [];
    if (selectedColour) orderedColours.unshift(selectedColour);
    const collapseColours = orderedColours?.length > MAX_COLOURS;
    const showSwatches = orderedColours?.length > MIN_COLOURS;

    if (!showSwatches) return null;

    return (
        <>
            <Link to={product ? product.href : ''}>
                <div className={bem('coloursList')}>
                    <ul className={bem('swatchList', { collapseColours })}>
                        {orderedColours.map((colour, index) => {
                            const { href, partNumber, swatch, buyable, label } = colour;
                            const active = product?.partNumber === partNumber;

                            return (
                                <li className={bem('swatch', { [activeForm]: active })} key={partNumber}>
                                    <Wrapper selected={active} productUrl={href} partNumber={partNumber} title={label} position={index + 1}>
                                        <Swatch
                                            colour={swatch?.HEX}
                                            url={swatch?.url?.replace('{width}', '50')}
                                            notBuyable={!buyable}
                                            colourClass={bem('swatchImage')}
                                        />
                                    </Wrapper>
                                </li>
                            );
                        })}
                    </ul>
                    {collapseColours && <div className={bem('moreColours')}>+</div>}
                </div>
            </Link>
        </>
    );
};

export default Colours;
